/* .loader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  width: 80px;
  height: 80px;
  padding-left: 0;
}
.loader:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #888075 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */


.paginationBttns {
  list-style: none;
  display: inherit;
}

.paginationBttns li.paginationActive a {
  background-color: black;
  color: white;
}

.rateMOdel .stars i {
  color: red;
  padding-top: 2px;
}


.info-div {
  display: flex;
  color: red;
  margin-bottom: 15px;
}

.info-div label {
  margin-right: 18px;
  color: black;
}

.cursor-pointer {
  cursor: pointer;
}


.side-filter{
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  width: 380px;
  height: 100vh;
  overflow: hidden;
  transition: 0.3s all ease-in-out;
  transform: translateX(-100%);
  background-color: #fff;
  z-index: 99999;
}

.side-filter-overlay{
  background-color: #0E0E0E91;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  z-index: 99999;
}
.side-filter-overlay.show{
  display: block;
}

.side-filter.open{
  transform: translateX(0);
  max-height: 100vh;
  overflow: visible;
}

.side-filter-inner{
  /* overflow-y: auto; */
  height: 100%;
}
.side-filter.open{
  transform: translateX(0);
}
.side-filter .close-filter{
  position: absolute;
  right: -55px;
  top: 10px;
  border: none;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #00000000;
  font-size: 30px;
  color: #fff; 
  transition: 0.3s ease; 
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.side-filter .close-filter:after{
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ffffff21;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease;
  transform: scale(0);
  transform-origin: center;
}
.side-filter .close-filter:hover::after{
  transform: scale(1);
  transition: 0.3s ease;
}

.side-filter-form{  
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 30px 10px 20px 20px;
}
.wrap-filter-option{
  padding: 0 0 0 30px;
  height: 83%;
  display: flex;
  flex-direction: column;
}
.side-filter-data{
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 30px;
}
.side-filter-foot{
  padding-top: 10px;
  border-top: 1px solid #c9c9c9;
  margin-top: auto;
}

/* .com-radio + label{
  padding-left: 37px;
  position: relative;
  margin-bottom: 0;
  line-height: 25px;
  min-height: 25px;
  cursor: pointer;
  font-size: 14px;
  color: #5c5d63;
}
.com-radio + label:before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #CED2DE;
  background-color: #fff;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.com-radio:checked + label:after{
  content: "";
  border-radius: 50%;
  position: absolute;
  left: 5px;
  width: 14px;
  height: 14px;
  background-color: #000;
  top: 5px;
} */


.custom-com-modal{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.custom-com-modal::after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000049;
  z-index: -1;
}
.custom-com-modal .custom-modal-inner{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-com-modal .custom-modal-body{
  background-color: #fff;
  padding: 0 20px 30px;
  max-height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  border: 2px solid #fff;
  width: 800px;
  max-width: 95%;
  position: relative;
}
.custom-modal-header{
    position: sticky;
    top: 0;
    background-color: #fff;
    text-align: right;
    margin-bottom: 10px;
    z-index: 1;
    padding: 10px 0;
}
.close-custom-modal{
  border: 1px solid #164ea2;
  border-radius: 20px;
  font-size: 14px;
  width: 25px;
  height: 25px;
  color: #164ea2;
  background-color: #fff;
}

@media(max-width: 767px){
  .wrap-filter-option{
    padding: 0;
  }
}
@media(max-width: 430px){
  .side-filter{
    width: 320px;
  }
  .side-filter .close-filter{
    right: 5px;
    color: #5c5d63;
  }
}
@media(max-width: 320px){
  .side-filter{
    width: 272px;
  }
}