/* .valid{
    display: none;
}
.paginationBttns{
    display: inherit;
    list-style: none;
}

.paginationActive a{
    background-color: #000;
    color: white;
}

.paginationBttns li a:hover{
    background-color: #000 !important;
    color: white !important;
} */

.viewWelcomeBoard img {
    width: 20% !important;
    margin-bottom: 20px;
}

.viewWelcomeBoard {
    text-align: center;
    margin: 100px auto 0;
}

.viewWelcomeBoard span {
    display: block;
    text-align: center;
}

.viewWelcomeBoard .textDesciptionWelcome {
    font-size: 30px;
}

.viewWelcomeBoard .textTitleWelcome {
    font-size: 20px;
}

.user_car {
    position: absolute;
    bottom: 20px;
    width: 100%;
}

.testimonal .carousel .tblocks {
    position: relative;
    min-height: 320px;
    width: 100%;
}

.language select {
    padding: 5px 20px;
    border-radius: 2px;
    border: solid 1px #ccc;
    background: white;
}

.language {
    background: #012F5B;
    padding: 6px 6px 0;
    text-align: right;
}

.profile-drop-menu {
    position: absolute;
    top: 30px;
    left: -156px;
    right: 32px;
}

.avtar_img.sb-avatar__text div span {
    font-size: 16px !important;
    color: white !important;
}

.avtar_img.sb-avatar__text div {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.c_hide {
    display: none;
}


/* search header  */

.frjscc {
    min-height: 40px !important;
}

.jZkBQk>.wrapper {
    border-radius: 4px !important;
    z-index: 1 !important;
}

.filter_list .filter_top ul.shape-items-show li {
    display: inline-block;
}

.fav-icon {
    cursor: pointer;
    position: absolute;
    left: 748px;
    font-size: 30px;
}

.heart {
    border: none;
    font-size: 25px;
}

.heart:hover {
    color: #003B75;
}

.heart.active {
    color: #003B75;
}

.like_btn {
    text-align: right;
    font-size: 30px;
    margin-bottom: 0;
    margin-left: auto;
    position: absolute;
    float: right;
    right: 26px;
    z-index: 9;

}

.card_1 span {
    display: block;
    text-align: right;
}

.active-all {
    border-radius: 4px;
    font-size: 15px;
    padding: 0 22px;
}

.shape-items-show li.c_hide {
    display: none !important;
}

.imgss img {
    width: 100%;
    height: 150px;
    object-fit: contain;
}

.shape-items-show li.active img {
    filter: invert(100%);
}

.filter_list .filter_top.d_color .shape-items-show li span {
    filter: invert(100%) !important;
}

.filter_search_right .search_cat_top .sort_by_box .sort_by_action {
    position: absolute;
    background: white;
    width: 100%;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    top: 45px;
    display: none;
    z-index: 9;
}

.filter_search_right .search_cat_top .sort_by_box .sort_by_action ul {
    padding: 15px 0;
    margin: 0;
}

.filter_search_right .search_cat_top .sort_by_box .sort_by_action ul li {
    list-style: none;
    padding: 10px 15px;
    font-size: 12px;
    color: #292f49;
    cursor: pointer;
}

.filter_search_right .search_cat_top .sort_by_box .sort_by_action ul li:hover {
    background: #164ea2;
    color: white;
}

.filter_search_right .search_cat_top .sort_by_box .sort_by_action ul li.activess {
    font-weight: 600;
}

.filter_search_right .seach_filter input {
    padding: 9px 15px 9px 40px;
    border: none;
    font-size: 13px;
    height: auto;
    font-weight: 500;
}


/* new css */

.dashboard-sec>.row>.col-lg-3 {
    padding: 32px 24px 0 24px;
}

.filter_list .filter_top ul li {
    width: calc(100%/4 - 6px) !important;
    margin: 0 3px 15px 3px !important;
}

.filter_list .j_type ul li {
    width: calc(100%/3 - 14px) !important;
    margin: 0 7px 15px 7px !important;
}

.filter_list .filter_top ul li span {
    font-size: 10px;
    color: #292F49;
}

[type=checkbox]:not(:checked)+label:before {
    top: 0px;
    width: 24px;
    height: 24px;
    border: 1px solid #CED2DE;
    background-color: #FFFFFF;
}

[type=checkbox]:not(:checked)+label,
[type=checkbox]:checked+label {
    padding-left: 37px;
    font-size: 14px;
    color: #5c5d63;
    margin-bottom: 1px;
}

[type=checkbox]:checked+label:before {
    top: 8px;
    left: 3px;
    width: 8px;
    height: 14px;
}

[type=checkbox]:checked+label:after {
    top: 4px;
    width: 24px;
    height: 24px;
    border: 1px solid #090F1D;
    background: #090F1D;
}

.filter_list .filter_btn .btn-black {
    min-width: 100%;
    border-radius: 4px;
    padding: 7.5px 5px;
    font-size: 14px;
    font-weight: 600;
}

.cus_switch .slider.round {
    border-radius: 34px;
    background-color: transparent;
}

.filter_list .filter_top.d_color .shape-items-show {
    margin: 0 -8px 4px;
}

.filter_list .filter_top.d_color .shape-items-show li {
    width: calc(100%/4 - 16px) !important;
    margin: 0 8px 12px 8px !important;
    padding: 3px 5px;
    background: #FFFFFF;
    border: 1px solid #CDCDCD;
}

.filter_list .filter_top.d_color .shape-items-show li.active {
    background-color: #090F1D;
    border-color: #090F1D;
}

.filter_list .filter_top.d_color .shape-items-show li span {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #5c5d63;
}

.filter_top {
    margin-bottom: 28px;
}

.filter_top~.filter_top.d_color {
    margin-top: -20px !important;
}

.filter_list .price_box input {
    height: 30px;
    min-width: 75px;
    font-size: 12px;
    color: #090F1D;
}

.filter_list .filter_top .filter_head h4 {
    font-size: 14px;
}

.shape-items-show li img {
    height: 31px;
}

@media screen and (min-width: 992px) {
    .dashboard-sec>.row>.col-lg-3 {
        max-width: 304px;
    }

    .dashboard-sec>.row>.col-lg-9 {
        max-width: calc(100% - 304px);
        flex: auto;
        padding-right: 0;
    }
}

.compare-product-btn {
    font-size: 13px;
    min-width: 140px;
}

.switch_color {
    position: absolute;
    float: right;
    right: 0;
    margin-right: 34.5%;
    z-index: 9;
}

.supplier-input {
    width: 100% !important;
}

.seive-select {
    height: 35px;
}

.filter_search_right .search_cat_top .form-group {
    margin: 0 -15px 0 5px;
}

.filter_search_right .search_cat_top .sort_by_box p {
    padding: 0 0px 8px 10px;
}

.seach_filter {
    margin-right: 0;
    margin-bottom: 3px;
}

.seach_filter .col-xl-4,
.seach_filter .col-lg-6,
.seach_filter .col-md-6 {
    padding-right: 5px;
}

.filter_search_right .search_cat_top .form-group .form-group {
    margin: 0;
}

.product_card .row {
    margin: 0;
}

.filter_search_right .search_cat_top {
    margin-bottom: 0;
}

/* .filter_search_right .search_cat_top .sort_by_box p {
    margin: 0;
} */

.card_1 .card_details h5 {
    font-size: 14px;
    font-weight: normal;
}

.card_1 .card_img img {
    height: 160px;
    object-fit: contain;
}

.filter_list.Jewelery_list .j_top li {
    margin: 15px 7px 0px 7px !important;
}

#loadMore {
    margin: 25px 0 0;
}

.filter_top {
    margin-bottom: 30px !important;
}

.supplier-input {
    width: 77.5% !important;
}

.multi_price .form-group {
    margin-bottom: 0.3rem !important;
}


.TitleBtns h4 {
    display: inline-block;
}

.TitleBtns button {
    display: inline-block;
    border: solid 2px #164EA2;
    color: #164EA2;
    min-width: auto;
    padding: 5px 10px;
    font-size: 15px;
    border-radius: 50px;
    margin-left: 25px;
}

.rateMOdel .form-group {
    position: relative;
}

.rateMOdel [type=checkbox]:not(:checked),
.rateMOdel [type=checkbox]:checked {
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 88;
}


.rateMOdel [type=checkbox]:checked+label:after,
.rateMOdel [type=checkbox]:not(:checked)+label:after {
    top: 0 !important;
    width: 24px !important;
    height: 24px !important;
}

.rateMOdel [type=checkbox]:checked+label:before {
    top: 4px !important;
}

.stars label {
    display: block;
}

.rate {

    height: 60px;
    padding: 0 0px;
    display: inline-block;
}

.rate:not(:checked)>input {
    position: absolute;
    top: -9999px;
}

.rate:not(:checked)>label {
    float: right;
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 50px;
    color: #ccc;
}

.rate:not(:checked)>label:before {
    content: '★ ';
}

.rate>input:checked~label {
    color: #0f3b75;
}

.rate:not(:checked)>label:hover,
.rate:not(:checked)>label:hover~label {
    color: #0f3b75;
}

.rate>input:checked+label:hover,
.rate>input:checked+label:hover~label,
.rate>input:checked~label:hover,
.rate>input:checked~label:hover~label,
.rate>label:hover~input:checked~label {
    color: #0f3b75;
}

.details_page .c_price .add_btn a.btn.btn-black {
    color: white;
}


.PopsData .modal-content {
    background: transparent;
    border: 0px;
}

.PopsData .modal-content .succes {
    background: white;
    padding: 15px;
    position: relative;
    border-radius: 5px;
    border-top: solid 8px #0f3b75;
    margin-bottom: 40px;
}


.PopsData .modal-content .succes .icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: solid 2px rgba(0, 0, 0, 0.16);
    display: block;
    margin: 30px auto;
    text-align: center;
    line-height: 90px;
}

.PopsData .modal-content .succes .icon i {
    font-size: 40px;
}

.PopsData .modal-content .succes h4 {
    text-align: center;
    color: black;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 15px;
    padding-bottom: 30px;
    background: url(../images/success-pop.png) no-repeat bottom center;
}


.PopsData .modal-content .succes p {
    text-align: center;
    color: black;
}


.PopsData .modal-content .succes .close {
    position: absolute;
    right: -41px;
    top: 40px;
    background: #A7A7A7;
    opacity: 1;
    border-radius: 0 5px 5px 0;
    padding: 6px 12px;
}

.PopsData .modal-content .succes .closes {
    width: 100px;
    padding: 8px;
    background: #0f3b75;
    text-align: center;
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    position: absolute;
    left: calc(50% - 50px);
    bottom: -15px;
    border-radius: 5px;
    border: 0;
}


.PopsData .modal-content .unsucces {
    background: white;
    padding: 15px;
    position: relative;
    border-radius: 5px;
    border-top: solid 8px #FF2300;
    margin-bottom: 40px;
}


.PopsData .modal-content .unsucces .icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: solid 2px rgba(0, 0, 0, 0.16);
    display: block;
    margin: 30px auto;
    text-align: center;
    line-height: 90px;
}

.PopsData .modal-content .unsucces .icon i {
    font-size: 40px;
}

.PopsData .modal-content .unsucces h4 {
    text-align: center;
    color: black;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 15px;
    padding-bottom: 30px;
    background: url(../images/cancel.png) no-repeat bottom center;
}


.PopsData .modal-content .unsucces p {
    text-align: center;
    color: black;
}


.PopsData .modal-content .unsucces .close {
    position: absolute;
    right: 0px;
    top: 0px;
    background: #A7A7A7;
    opacity: 1;
    /* border-radius: 0 5px 5px 0; */
    padding: 6px 12px;
}


.PopsData .modal-content .unsucces .closes {
    width: 100px;
    padding: 8px;
    background: black;
    text-align: center;
    color: #FF2300;
    font-size: 16px;
    text-transform: uppercase;
    position: absolute;
    left: calc(50% - 50px);
    bottom: -15px;
    border-radius: 5px;
    border: 0;
}


.product_card .card_1 .card_details h4 {
    color: black !important;
    font-weight: bold !important;
    font-size: 16px;
}

.product_card .card_1 .card_details p {
    font-size: 14px;
}

.more-review>.previous {
    background-color: #f1f1f1;
    color: black;
    border-radius: 5px;
}

.more-review>.next {
    background-color: #003B75;
    color: white;
    border-radius: 5px;
}

.more-review>span {
    text-decoration: none;
    display: inline-block;
    padding: 10px 18px;
}

.more-review>span:hover {
    background-color: #ddd;
    color: black;
}

.hwerating h6 {
    margin-top: 0.5rem;
    margin-right: 1rem;
    font-size: 16px;
}

.tbles td:nth-child(2)>div {
    justify-content: center;
}

.tbles td:nth-child(1) {

    min-width: none;
}

/* div#__react-alert__ > div:before {
    content: "";
    position: absolute;
    background: #0000004a;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

div#__react-alert__ {
    position: absolute;
    z-index: 9999;
    display: block;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
} */

p.sort_b_h.text-right.lang_p {
    font-size: 27px !important;
}


.lang_list {
    min-width: 100px;
    background-color: #194ea2 !important;
    overflow: hidden;
}

.sort_by_box .lang_p {
    margin: 8px 0;
    padding: 0 !important;
}

.lang_list ul {
    padding: 0 !important;
}

.lang_list ul li {
    color: rgb(255 255 255 / 55%) !important;
}

.lang_list ul li:hover {
    color: #fff !important;
}

.lang_list ul li.active {
    color: #fff !important;
}

.nav_btns {
    border: none !important;
    padding: 0 !important;
}

.H_title {
    color: #090F1D;
    text-transform: capitalize;
}

td.text-right.Active-st-td {
    text-align: center !important;
}

button#dismiss-modal-yes {
    right: -31%;
    margin: auto;
}

button.closes.no {
    left: 5% !important;
}

h4.cancle_plan {
    cursor: pointer;
}

select.form-control.top-cat-dropdown {
    width: 20%;
    position: absolute;
    border: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

input.form-control.txt-box.top-search {
    width: 80%;
    float: right;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.innerdiv h3 {
    top: 0;
    /* margin-top: -11%; */
}

.innerdiv .device_d {

    padding: 8px 10px;
    margin-bottom: 20px;
}

.innerdiv .sub_plan_box {
    height: 111%;
    cursor: pointer;

}

.innerdiv .sub_plan_box h3 {
    margin-bottom: 29px
}

.quick_box ul li {
    display: inline-block;
    background-color: white;
    margin-left: 3px;
    color: gray;
    padding: 8px;
    border-radius: 15px;
    border: 2px solid #cdd2de;
    margin-top: 5px;
}

.quick_box p {
    text-align: center;
    font-size: larger;
}

.quick_box ul li.active {
    background-color: #003B75;
    color: white;
}

.progress-loader {
    overflow: hidden;
}

.progress-loader::after {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    display: block;
}

.lode-perc {
    width: 10%;
    bottom: 0;
    left: -2%;
    position: fixed;
    right: 0;
    margin: 0 auto;
    top: 38%;
    z-index: 1111;
}

.lode-perc span {
    position: absolute;
    font-size: 36px;
    line-height: 30px;
    text-align: center;
    width: 135px;
    color: #fff;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

.lode-perc strong {
    font-size: 16px;
    font-weight: normal;
    display: block;
}



/* responsive css */

.navbar-toggler-icon:before {
    content: "\f0c9";
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    left: 0;
    right: 0;
    color: #fff;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.paginationBttns {
    padding: 0;
}

button.btns.greys.hblack.dl {
    background: #ced2de;
    border: solid 2px #ced2de;
    margin-right: 10px;
    color: #767A8A;
    border-radius: 5px;
}

@media screen and (max-width: 1100px) {
    .details_page .product_part .TitleBtns .not_a.text-capitalize {
        width: 100%;
    }

    .details_page .product_part .TitleBtns .average-rating {
        width: 100%;
    }

    .details_page .product_part .TitleBtns .average-rating button {
        margin-left: 0px;
        margin-bottom: 1rem;
    }

    .pro_des .pro_d_text h4 {
        word-break: break-all;
    }

    .hides {
        display: flex;
    }

    .hides .form-inline {
        margin: 0 !important;
    }

    .hides .form-inline button {
        margin-left: 0px !important;
    }

    .filter_search_right .search_cat_top .sort_by_box p {
        padding: 8px 0px 8px 10px;
    }
}

@media screen and (max-width: 992px) {
    .filter_search_right .seach_filter .input_icon {
        top: 13px;
    }

    .seach_filter {
        margin-bottom: 12px;
        margin-top: 1rem;
    }


    .simpleLens-big-image-container {
        height: 300px !important;
    }
}

@media screen and (max-width: 767px) {
    .MyProfiles {
        font-size: 0 !important;
        position: relative;
    }

    .MyProfiles:before {
        content: "\f2bd";
        font: normal normal normal 16px/1 FontAwesome;
        margin-right: 5px;
    }

    .bg_img .homes .btn-groups {
        margin-left: 5px;
    }

    p.sort_b_h.text-right.lang_p {
        font-size: 20px !important;
        margin-bottom: 0;
    }

    .bg_img .homes .btn-groups a {
        padding: 0 3px;
    }

    header .logo img {
        width: 100px;
    }

    .filter_search_right .search_cat_top .sort_by_box {
        margin: 0;
    }

    .filter_search_right .search_cat_top .sort_by_box p b {
        padding: 0;
        padding-right: 0;
    }

    input.form-control.txt-box.top-search {
        width: 74%;
    }

    select.form-control.top-cat-dropdown {
        width: 26%;
    }

    .conPart .tbles tr:last-child td {
        white-space: nowrap;
        padding: 5px;
    }

    .actions .btns {
        min-width: 47%;
    }

    .filter_search_right .seach_filter .input_icon {
        left: 30px;
        top: 15px;
    }

    .filter_table {
        margin-top: 1rem;
    }

    .product_card .col-md-3 {
        border-left: 1px solid #ccc;
    }

    .product_card .col-md-3:first-child {
        border-top: 1px solid #ccc;
    }



}

@media screen and (max-width: 583px) {

    .pro_images {
        margin-bottom: 1rem;
    }

}

.nameW {
    position: relative;
}

.nameW i {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background-color: #003B75;
    font-size: 1.5rem;
    border-radius: 30px;
    color: #fff;
    padding: 9px;
}

.nameW .sb-avatar.sb-avatar--src {
    width: 130px !important;
    height: 130px !important;
}

.nameW .sb-avatar img {
    width: 130px !important;
    height: 130px !important;
}

.mesList li {
    position: relative;
}

.mesList li:hover .total_unseen {
    color: #000 !important;
}

.total_unseen {
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 1;
    background: #ffdaa4;
    border: 0;
    font-size: 12px;
    width: 20px;
    height: 20px;
    border: 1px solid #ffdaa4;
    border-radius: 50%;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* lekhan */
.nav-right-box {
    display: flex;
    align-items: center;
    justify-content: end;
}

.options.filter_search_right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.options.filter_search_right .search_cat_top {
    margin-bottom: 0;
    padding: 0;
    border-bottom: 0;
}

.options.filter_search_right .search_cat_top p b {
    margin-right: 0 !important;
    padding-right: 0 !important;
}

/* Aks */
.col-lg-3.col-md-4.reg-plans {
    margin-bottom: 3%;
}

















.country_list {
    min-width: 100px;
    background-color: #194ea2 !important;
    overflow: hidden;
}

.sort_by_box .lang_p {
    margin: 8px 0;
    padding: 0 !important;
}

.country_list ul {
    padding: 0 !important;
}

.country_list ul li {
    color: rgb(255 255 255 / 55%) !important;
}

.country_list ul li:hover {
    color: #fff !important;
}

.country_list ul li.active {
    color: #fff !important;
}

.imgEdits {
    position: relative;
}

.imgEdits .close-vid {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
}

.imgEdits .close-vid img {
    cursor: pointer;
}