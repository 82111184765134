.error-page-sec {
    height: 100vh;
    padding: 50px 0;
}
.error-page-sec .inner-content {
    height: 100%;
}
.error-page-sec .inner-content > .container {
    height: 100%;
}
.error-page-sec .inner-content > .container > .row {
    height: 100%;
}
.error-page-sec .inner-content > .container > .row .titlehead {
    font-size: 80px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #164ea2;
}
.error-page-sec .inner-content > .container > .row .subtitle {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #000;
}
.error-page-sec .inner-content > .container > .row .paratext {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0;
    color: #90b7f2;
}

.error-page-sec .inner-content > .container > .row .btn {
    border-radius: 30px;
    font-size: 20px;
    font-weight: 500;
    background-color: #164ea2;
    color: #fff;
    margin-top: 30px;
}
.filter_table .not_a {
    display: block;
    width: 100%;
    height: 100%;
  }
  .reg_2_page .sub_plan_box {
    height: 100%;
  }
  .reg_2_page .sub_plan_box h3 {
    font-size: 19px;
  }
  .details_page .pro_images .simpleLens-gallery-container .simpleLens-container {
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  .details_page .pro_images .simpleLens-gallery-container .simpleLens-container .simpleLens-big-image {
    width: auto;
    max-height: 500px;
}
.details_page .pro_images .simpleLens-gallery-container .simpleLens-container 
.simpleLens-big-image-container {
  height: 100%;
    padding: 30px;
}
.details_page .pro_images {
  /* height: auto; */
}
  .product_card .card_1 .card_details h4 {
    display: inline-flex;
    width: 50%;
  }
  .product_card .card_1 .card_details h4 + p {
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
  .product_card .card_1 .card_details h4 + p span {
    margin-left: 4%;
    /* width: 58%; */
  }
  .details_page .product_part .TitleBtns .not_a.text-capitalize {
    align-items: center;
    width: 70%;
    display: inline-flex;
  }
  .details_page .product_part .TitleBtns .not_a.text-capitalize h6 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .details_page .product_part .TitleBtns .not_a.text-capitalize .not_a {
    width: 50%;
  }
  .details_page .product_part .TitleBtns .average-rating {
    width: 30%;
  }

  .dashboard-sec .col-lg-3.diamond {
    padding: 0%!important;
    padding-right: 4%!important;
  }

  .details_page .c_price .add_btn a.btn-black:hover {
    background: #164ea2;
  }


  .pdf_download_row{
  display: block;
  padding-right: 3%;
}
.quick_box li {
  cursor: pointer;
}
  @media screen and (max-width: 1199px) {
    .error-page-sec .inner-content > .container > .row .titlehead {
        font-size: 70px;
    }
    .error-page-sec .inner-content > .container > .row .subtitle {
        font-size: 25px;
    }
    .error-page-sec .inner-content > .container > .row .paratext {
        font-size: 17px;
    }
      .error-page-sec .inner-content > .container > .row .btn {
        font-size: 17px;
    }
  }

  @media screen and (max-width: 767px) {
    .error-page-sec {
        height: 100%;
    }
  }


/* 12-04-2022 */
@media screen and (max-width: 991px) {
  .conPart .titles .form-inline button {
    font-size: 9px !important;
    padding: 0 8px;
  }
  .dashboard-sec .col-lg-3 {
    padding-top: 0 !important;
  }
  .filter_search_right .seach_filter .input_icon {
    top: 24px !important;
  }
  .conPart form > .row {
    position: relative;
  }
  .conPart form > .row .filter_top {
    margin: 0;
    bottom: 35px;
    right: 15px;
    left: unset;
  }
}

@media screen and (max-width: 960px) {
  .conPart .titles .hides {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 767px) {
  .filter_search_right .search_cat_top > .row .col-lg-12 {
    padding-left: 0;
  }
  .filter_search_right .search_cat_top .form-group {
    margin: 0 0 0 0 !important;
  }
  .conPart .titles .form-inline label {
    margin-left: 0 !important;
  }
  .filter_search_right .search_cat_top .sort_by_box p {
    padding: 8px 0 8px 0 !important;
  }
}


/* 12-04-2022 End */

.navbar-toggler-icon:before {
  font: normal normal normal 22px/1 FontAwesome !important;
}

.bg_img .homes .navbar-toggler {
  border: none !important;
}

.simpleLens-big-image-container {
  display: flex !important;
  align-items: center;
  justify-content: center;
}