@charset "UTF-8";
@import "../css/bootstrap.css";
@import "../css/responsive.css";
@import "../css/developer.css";
@import "../css/font-awesome.min.css";
@import "../css/owl.carousel.min.css";
@import "../css/select2.min.css";
@import "../css/mobiscroll.jquery.min.css";
@import "../css/jquery-ui.css";
@import "../css/jquery.simpleGallery.css";
@import "../css/jquery.simpleLens.css";
@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter-Bold.woff2") format("woff2"), url("../fonts/Inter-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter-ExtraBold.woff2") format("woff2"), url("../fonts/Inter-ExtraBold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter-Black.woff2") format("woff2"), url("../fonts/Inter-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter-ExtraLight.woff2") format("woff2"), url("../fonts/Inter-ExtraLight.woff") format("woff");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter-Light.woff2") format("woff2"), url("../fonts/Inter-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter-Medium.woff2") format("woff2"), url("../fonts/Inter-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter-Regular.woff2") format("woff2"), url("../fonts/Inter-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter-SemiBold.woff2") format("woff2"), url("../fonts/Inter-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter-Thin.woff2") format("woff2"), url("../fonts/Inter-Thin.woff") format("woff");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "icomoon";
    src: url("../fonts/icomoon.eot?epvyu3");
    src: url("../fonts/icomoon.eot?epvyu3#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?epvyu3") format("truetype"), url("../fonts/icomoon.woff?epvyu3") format("woff"), url("../fonts/icomoon.svg?epvyu3#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^=icon-],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-360:before {
    content: "";
}

.icon-add_1:before {
    content: "";
}

.icon-add_2:before {
    content: "";
}

.icon-add_3:before {
    content: "";
    color: #757575;
}

.icon-add_4:before {
    content: "";
}

.icon-arrow:before {
    content: "";
}

.icon-arrow_blue:before {
    content: "";
    color: #406fdb;
}

.icon-arrow_left:before {
    content: "";
}

.icon-check-mark_1 .path1:before {
    content: "";
    color: #1a1a1a;
}

.icon-check-mark_1 .path2:before {
    content: "";
    margin-left: -1em;
    color: white;
}

.icon-check-mark_2:before {
    content: "";
}

.icon-check-mark_3:before {
    content: "";
}

.icon-check-mark_4:before {
    content: "";
    color: #fff;
}

.icon-check-mark_5:before {
    content: "";
}

.icon-chevron_black:before {
    content: "";
}

.icon-chevron_down:before {
    content: "";
}

.icon-chevron_gray-left:before {
    content: "";
    color: #cdcdcd;
}

.icon-chevron_gray-right:before {
    content: "";
    color: #cdcdcd;
}

.icon-close:before {
    content: "";
    font-size: 30px;
}

.icon-close_1:before {
    content: "";
}

.icon-close_2:before {
    content: "";
}

.icon-close_white:before {
    content: "";
    color: #fff;
}

.icon-device_1:before {
    content: "";
}

.icon-device_2:before {
    content: "";
}

.icon-download:before {
    content: "";
}

.icon-ellipse:before {
    content: "";
}

.icon-eye:before {
    content: "";
}

.icon-facebook:before {
    content: "";
}

.icon-Group-16 .path1:before {
    content: "";
    color: #3b3b3b;
}

.icon-Group-16 .path2:before {
    content: "";
    margin-left: -1em;
    color: white;
}

.icon-heart_black:before {
    content: "";
}

.icon-heart_gray:before {
    content: "";
    color: #b2b2b2;
}

.icon-img .path1:before {
    content: "";
    color: white;
}

.icon-img .path2:before {
    content: "";
    margin-left: -1em;
    color: #e6e6e6;
}

.icon-img .path3:before {
    content: "";
    margin-left: -1em;
    color: #cdcdcd;
}

.icon-img_1:before {
    content: "";
}

.icon-img_2:before {
    content: "";
}

.icon-img_3:before {
    content: "";
}

.icon-img_4:before {
    content: "";
}

.icon-img_5:before {
    content: "";
}

.icon-insta:before {
    content: "";
}

.icon-linkedin:before {
    content: "";
}

.icon-message_black:before {
    content: "";
}

.icon-message_gray:before {
    content: "";
    color: #b2b2b2;
}

.icon-minus:before {
    content: "";
    color: #fff;
}

.icon-more-options:before {
    content: "";
}

.icon-profile-img:before {
    content: "";
}

.icon-progress .path1:before {
    content: "";
    color: white;
}

.icon-progress .path2:before {
    content: "";
    margin-left: -1em;
    color: white;
    opacity: 0.3;
}

.icon-ring:before {
    content: "";
    color: #f8f8f8;
}

.icon-search_1:before {
    content: "";
}

.icon-search_2:before {
    content: "";
}

.icon-spacing:before {
    content: "";
    color: #fff;
}

.icon-stone_1:before {
    content: "";
}

.icon-stone_2:before {
    content: "";
}

.icon-stone_3:before {
    content: "";
    color: #f8f8f8;
}

.icon-toggle .path1:before {
    content: "";
    color: #1a1a1a;
}

.icon-toggle .path2:before {
    content: "";
    margin-left: -1em;
    color: white;
}

.icon-twitter:before {
    content: "";
}

.icon-watch_1:before {
    content: "";
    color: #f8f8f8;
}

.icon-watch_2:before {
    content: "";
    color: #f8f8f8;
}

.icon-watch_3:before {
    content: "";
    color: #f8f8f8;
}

.icon-watch_4:before {
    content: "";
}

.icon-account:before {
    content: "";
    color: #9199a7;
}

.icon-arrow-right-gray:before {
    content: "";
    color: rgba(0, 0, 0, 0.15);
}

.icon-attachment-clip:before {
    content: "";
    color: #9199a7;
}

.icon-bell:before {
    content: "";
    color: #3224ae;
}

.icon-calendar:before {
    content: "";
    color: #9199a7;
}

.icon-camera:before {
    content: "";
    color: #9199a7;
}

.icon-camera-blue:before {
    content: "";
    color: #3224ae;
}

.icon-cancel:before {
    content: "";
    color: #404b63;
}

.icon-clock:before {
    content: "";
    color: #9199a7;
}

.icon-credit-card:before {
    content: "";
    color: #3224ae;
}

.icon-decline .path1:before {
    content: "";
    color: #e80000;
}

.icon-decline .path2:before {
    content: "";
    margin-left: -1em;
    color: white;
}

.icon-delete:before {
    content: "";
    color: #fff;
}

.icon-discuss-issue:before {
    content: "";
    color: #3224ae;
}

.icon-edit:before {
    content: "";
    color: #3224ae;
}

.icon-left-arrow:before {
    content: "";
    color: #fff;
}

.icon-message:before {
    content: "";
    color: #3224ae;
}

.icon-more:before {
    content: "";
    color: #fff;
}

.icon-password:before {
    content: "";
    color: #3224ae;
}

.icon-phone:before {
    content: "";
    color: #747e8e;
}

.icon-search:before {
    content: "";
    color: #fff;
}

.icon-share:before {
    content: "";
}

.icon-star-inactive:before {
    content: "";
    color: #9199a7;
}

.icon-success .path1:before {
    content: "";
    color: #8dc640;
}

.icon-success .path2:before {
    content: "";
    margin-left: -0.9990234375em;
    color: white;
    opacity: 0.2;
}

.icon-success .path3:before {
    content: "";
    margin-left: -0.9990234375em;
    color: white;
}

.icon-support:before {
    content: "";
    color: #3224ae;
}

.icon-wallet:before {
    content: "";
    color: #9199a7;
}

.datepicker td,
.datepicker th {
    width: 35px;
    height: 35px;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
    background-color: #34D086;
    background-image: none;
}

.cust_radio {
    display: block;
    position: relative;
    padding-left: 1.6875rem;
    margin-bottom: 0.75rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* Hide the browser's default radio button */

.cust_radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
    top: 0;
}


/* Create a custom radio button */

.cust_radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1rem;
    width: 1rem;
    background-color: #fff;
    border: solid 1px #B4B4B4;
    border-radius: 50%;
}


/* On mouse-over, add a grey background color */


/* .cust_radio:hover input ~ .checkmark {
  background-color: #ccc;
} */


/* When the radio button is checked, add a blue background */

.cust_radio input:checked~.checkmark {
    border-color: #01C8E7;
}


/* Create the indicator (the dot/circle - hidden when not checked) */

.cust_radio .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the indicator (dot/circle) when checked */

.cust_radio input:checked~.checkmark:after {
    display: block;
}


/* Style the indicator (dot/circle) */

.cust_radio .checkmark:after {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #01C8E7;
}

.btn {
    min-width: 11.875rem;
    border-radius: 4px;
    text-transform: capitalize;
    padding: 10px 0;
}

@media screen and (max-width: 767px) {
    .btn {
        min-width: 10.875rem;
    }
}

.btn-lg {
    min-width: 11.875rem;
    border-radius: 4px;
    text-transform: capitalize;
    padding: 10px 0;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.btn-lg.full {
    width: 100% !important;
}

@media screen and (max-width: 767px) {
    .btn-lg {
        min-width: 10.875rem;
    }
}

.btn-black {
    background: #164EA2;
    color: white;
    border: solid 2px #164EA2;
    text-transform: capitalize;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    font-size: 1.25rem;
}

.btn-black:hover {
    background: transparent;
    border: solid 2px #164EA2;
    color: #164EA2;
}

.btn-gray {
    background: #CED2DE;
    color: #767A8A;
    text-transform: capitalize;
    font-size: 1.25rem;
    font-weight: 500;
    border: solid 2px #CED2DE;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.btn-gray:hover {
    background: transparent;
    border: solid 2px #767A8A;
    color: #767A8A;
}


.btn-blue {
    background: #003b75;
    color: #CDCDCD;
    text-transform: capitalize;
    font-size: 1.25rem;
    font-weight: 500;
    border: solid 2px #003b75;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.btn-blue:hover {
    background: transparent;
    border: solid 2px #003b75;
    color: #767A8A;
}

.btn-gray.op30 {
    background: rgba(26, 26, 26, 0.3);
    color: white;
    padding-left: 15px;
    padding-right: 15px;
}

.btn-gray.op30:hover {
    border-color: transparent;
}

.btn-white {
    text-transform: capitalize;
    background: white;
    color: #292F49;
    font-size: 1.25rem;
    border: solid 2px #CDCDCD;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.btn-white:hover {
    background: transparent;
    border: solid 2px #292F49;
    color: #292F49;
}

.form-group {
    margin-bottom: 1.5rem;
    position: relative;
}

.form-group label {
    font-size: 1rem;
    color: #292F49;
    font-weight: 600;
    margin-bottom: 10px;
}

.form-group .input_icon {
    position: absolute;
    top: 40px;
    right: 15px;
    opacity: 0.5;
}

.form-group.chkbx label {
    font-size: 1rem;
    color: #292F49;
    font-weight: 400;
    margin-bottom: 10px;
}

.form-control {
    border: 1px solid #CED2DE;
    border-radius: 4px;
    height: 48px;
    color: #1a1a1a;
    font-weight: 500;
}

.form-control::-webkit-input-placeholder {
    color: #868686;
}

.form-control::-moz-placeholder {
    color: #868686;
}

.form-control:-ms-input-placeholder {
    color: #868686;
}

.form-control::-ms-input-placeholder {
    color: #868686;
}

.form-control::placeholder {
    color: #868686 !important;
}

.greyBG {
    background: rgba(243, 243, 243, 0.5);
    height: 60px;
}


/* Remove default checkbox */

[type=checkbox]:not(:checked),
[type=checkbox]:checked {
    position: absolute;
    left: -9999px;
}

[type=checkbox]:not(:checked)+label,
[type=checkbox]:checked+label {
    position: relative;
    overflow: inherit;
    padding-left: 25px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    -webkit-user-select: none;
    /* webkit (safari, chrome) browsers */
    -moz-user-select: none;
    /* mozilla browsers */
    -khtml-user-select: none;
    /* webkit (konqueror) browsers */
    -ms-user-select: none;
    /* IE10+ */
}


/* checkbox aspect */

[type=checkbox]+label:before,
[type=checkbox]+label:after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 1;
    -webkit-transition: 0.2s;
    transition: 0.2s;
}


/* Unchecked styles */

[type=checkbox]:not(:checked)+label:before {
    top: 4px;
    width: 19px;
    height: 19px;
    border: 2px solid #CED2DE;
    border-radius: 4px;
}

@media screen and (max-width: 992px) {
    [type=checkbox]:not(:checked)+label:before {
        top: 2px;
    }
}

[type=checkbox]:not(:checked)+label:after {
    top: 4px;
    width: 19px;
    height: 19px;
    z-index: 0;
}

@media screen and (max-width: 992px) {
    [type=checkbox]:not(:checked)+label:after {
        top: 2px;
    }
}


/* Checked styles */

[type=checkbox]:checked+label:before {
    top: 2px;
    width: 6px;
    height: 12px;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid #1a1a1a;
    border-bottom: 1px solid #1a1a1a;
    -webkit-transform: rotateZ(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 20% 40%;
    transform-origin: 100% 100%;
}

[type=checkbox]:checked+label:after {
    top: 4px;
    width: 19px;
    height: 19px;
    border: 1px solid #1a1a1a;
    z-index: 0;
    background: #1a1a1a;
    border-radius: 4px;
}


/* disabled checkbox */

[type=checkbox]:disabled:not(:checked)+label:before,
[type=checkbox]:disabled:checked+label:before {
    top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #444;
    width: 19px;
    height: 19px;
    border: 3px solid #444;
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
}

[type=checkbox]:disabled+label {
    color: #555;
}

[type=checkbox]:disabled:not(:checked)+label:hover:before {
    border-color: #01C8E7;
}

[type=checkbox]:checked+label:before {
    top: 7px;
    left: 2px;
    width: 6px;
    height: 12px;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: rotateZ(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 20% 40%;
    transform-origin: 100% 100%;
}

.hw-30 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
}

.mini {
    /* Unchecked styles */
    /* Checked styles */
}

.mini [type=checkbox]:not(:checked)+label,
.mini [type=checkbox]:checked+label {
    padding-left: 19px;
    margin: 0;
}

.mini [type=checkbox]+label:before,
.mini [type=checkbox]+label:after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 1;
    -webkit-transition: 0s;
    transition: 0s;
}

.mini [type=checkbox]:not(:checked)+label:before {
    top: 4px;
    width: 19px;
    height: 19px;
    border: none;
}

.mini [type=checkbox]:not(:checked)+label:after {
    top: 0px;
    width: 19px;
    height: 19px;
    border: 0px solid #1a1a1a;
    z-index: 0;
    content: "+";
    text-align: center;
    font-size: 21px;
}

.mini [type=checkbox]:checked+label:before {
    top: 50%;
    left: 4.5px;
    width: 9px;
    height: 2px;
    background: white;
    border: 0px;
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
    -webkit-transform-origin: unset;
    transform-origin: unset;
}

.mini [type=checkbox]:checked+label:after {
    top: 4px;
    width: 19px;
    height: 19px;
    border: 1px solid #1a1a1a;
    z-index: 0;
    background: #1a1a1a;
    border-radius: 50%;
}

.mini.inss [type=checkbox]:not(:checked)+label:before {
    top: 4px;
    width: 19px;
    height: 19px;
    border: none;
    background: transparent;
    border-radius: 2px;
}

.mini.inss [type=checkbox]:not(:checked)+label:after {
    z-index: 5;
}

.cus_switch {
    /* Rounded sliders */
}

@media screen and (max-width: 767px) {
    .cus_switch {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
        margin-top: 15px !important;
    }
}

.cus_switch b {
    padding-right: 6px;
}

.cus_switch .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 22px;
}

.cus_switch .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.cus_switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border: solid 2px #000;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.cus_switch .slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 2px;
    background-color: #000;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.cus_switch input:checked+.slider {
    background-color: #164EA2;
    border: solid 2px #164EA2;
}

.cus_switch input:focus+.slider {
    -webkit-box-shadow: 0 0 1px #164EA2;
    box-shadow: 0 0 1px #164EA2;
}

.cus_switch input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    background: white;
    transform: translateX(26px);
}

.cus_switch .slider.round {
    border-radius: 34px;
}

.cus_switch .slider.round:before {
    border-radius: 50%;
}

.owl-nav {
    text-align: center;
}

.owl-nav button {
    background: #1a1a1a !important;
    margin: 0px 5px;
    width: 25px;
    height: 25px;
    border-radius: 4px;
    color: white !important;
    font-size: 12px !important;
}

.owl-nav button i:before {
    color: white;
}

input,
textarea {
    -webkit-appearance: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    font-weight: 500;
    color: #1a1a1a;
}


/* White */


/* Black */


/* Gray */


/* Blue */


/* Yellow */


/* Green  */


/* Red */


/* Text Color Class */


/* White */

.white {
    color: #fff;
}


/* Black */

.black {
    color: #000;
}

.black-3B {
    color: #090F1D;
}


/* Gray */

.gray-1A {
    color: #164EA2;
}

.gray-65 {
    color: #656565;
}

.gray-75 {
    color: #757575;
}

.gray-7B {
    color: #7B7B7B;
}

.gray-60 {
    color: #606060;
}


/* Blue */

.blue-E7 {
    color: #01C8E7;
}

.blue-D2 {
    color: #00B6D2;
}


/* Yellow */

.yellow-38 {
    color: #E6BE38;
}


/* Green */

.green-6B {
    color: #19D86B;
}


/* Red */

.red-19 {
    color: #D81919;
}


/* Background Color Class */


/* White */

.bg-white {
    background-color: #fff;
}


/* Black */

.bg-black {
    background-color: #000;
}

.bg-black-3B {
    background-color: #090F1D;
}


/* Gray */

.bg-gray-1A {
    background-color: #164EA2;
}


/* Blue */

.bg-blue-E7 {
    background-color: #01C8E7;
}

.bg-blue-D2 {
    background-color: #00B6D2;
}

.w-20 {
    width: 20%;
}


/* Roboto Font */

.fr {
    font-family: "inter";
    font-weight: normal;
}

.fm {
    font-family: "inter";
    font-weight: 500;
}

.fb {
    font-family: "inter";
    font-weight: bold !important;
}

.fblack {
    font-family: "inter";
    font-weight: 900;
}

.f-700 {
    font-weight: 700;
}

.f-600 {
    font-weight: 600;
}

.font-60 {
    font-size: 3.75rem;
}

.font-47 {
    font-size: 2.9375rem;
}

.font-40 {
    font-size: 2.5rem;
}

.font-26 {
    font-size: 1.625rem;
}

.font-25 {
    font-size: 1.5625rem;
}

.font-30 {
    font-size: 1.875rem;
}

.font-24 {
    font-size: 1.5rem;
}

.font-22 {
    font-size: 1.375rem;
}

.font-20 {
    font-size: 1.25rem;
}

.font-18 {
    font-size: 1.2rem !important;
    line-height: 22px;
}

.font-16 {
    font-size: 1rem;
}

.font-14 {
    font-size: 0.875rem;
}

.font-13 {
    font-size: 0.8125rem;
}

.font-12 {
    font-size: 0.75rem;
}

.datepicker td,
.datepicker th {
    width: 35px;
    height: 35px;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
    background-color: #34D086;
    background-image: none;
}

.cust_radio {
    display: block;
    position: relative;
    padding-left: 1.6875rem;
    margin-bottom: 0.75rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* Hide the browser's default radio button */

.cust_radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
    top: 0;
}


/* Create a custom radio button */

.cust_radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1rem;
    width: 1rem;
    background-color: #fff;
    border: solid 1px #B4B4B4;
    border-radius: 50%;
}


/* On mouse-over, add a grey background color */


/* .cust_radio:hover input ~ .checkmark {
  background-color: #ccc;
} */


/* When the radio button is checked, add a blue background */

.cust_radio input:checked~.checkmark {
    border-color: #01C8E7;
}


/* Create the indicator (the dot/circle - hidden when not checked) */

.cust_radio .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the indicator (dot/circle) when checked */

.cust_radio input:checked~.checkmark:after {
    display: block;
}


/* Style the indicator (dot/circle) */

.cust_radio .checkmark:after {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #01C8E7;
}

.btn {
    min-width: 11.875rem;
    border-radius: 4px;
    text-transform: capitalize;
    padding: 10px 0;
}

@media screen and (max-width: 767px) {
    .btn {
        min-width: 10.875rem;
    }
}

.btn-lg {
    min-width: 11.875rem;
    border-radius: 4px;
    text-transform: capitalize;
    padding: 10px 0;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.btn-lg.full {
    width: 100% !important;
}

@media screen and (max-width: 767px) {
    .btn-lg {
        min-width: 10.875rem;
    }
}

.btn-black {
    background: #164EA2;
    color: white;
    border: solid 2px #164EA2;
    text-transform: capitalize;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    font-size: 1.25rem;
}

.btn-black:hover {
    background: transparent;
    border: solid 2px #164EA2;
    color: #164EA2;
}

.btn-gray {
    background: #CED2DE;
    color: #767A8A;
    text-transform: capitalize;
    font-size: 1.25rem;
    font-weight: 500;
    border: solid 2px #CED2DE;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.btn-gray:hover {
    background: transparent;
    border: solid 2px #767A8A;
    color: #767A8A;
}

.btn-gray.op30 {
    background: rgba(26, 26, 26, 0.3);
    color: white;
    padding-left: 15px;
    padding-right: 15px;
}

.btn-gray.op30:hover {
    border-color: transparent;
}

.btn-white {
    text-transform: capitalize;
    background: white;
    color: #292F49;
    font-size: 1.25rem;
    border: solid 2px #CDCDCD;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.btn-white:hover {
    background: transparent;
    border: solid 2px #292F49;
    color: #292F49;
}

.form-group {
    margin-bottom: 1.5rem;
    position: relative;
}

.form-group label {
    font-size: 1rem;
    color: #292F49;
    font-weight: 600;
    margin-bottom: 10px;
}

.form-group .input_icon {
    position: absolute;
    top: 40px;
    right: 15px;
    opacity: 0.5;
}

.form-group.chkbx label {
    font-size: 1rem;
    color: #292F49;
    font-weight: 400;
    margin-bottom: 10px;
}

.form-control {
    border: 1px solid #CED2DE;
    border-radius: 4px;
    height: 48px;
    color: #1a1a1a;
    font-weight: 500;
}

.form-control::-webkit-input-placeholder {
    color: #1a1a1a;
}

.form-control::-moz-placeholder {
    color: #1a1a1a;
}

.form-control:-ms-input-placeholder {
    color: #1a1a1a;
}

.form-control::-ms-input-placeholder {
    color: #1a1a1a;
}

.form-control::placeholder {
    color: #1a1a1a;
}

.greyBG {
    background: rgba(243, 243, 243, 0.5);
    height: 60px;
}


/* Remove default checkbox */

[type=checkbox]:not(:checked),
[type=checkbox]:checked {
    position: absolute;
    left: -9999px;
}

[type=checkbox]:not(:checked)+label,
[type=checkbox]:checked+label {
    position: relative;
    overflow: inherit;
    padding-left: 25px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    -webkit-user-select: none;
    /* webkit (safari, chrome) browsers */
    -moz-user-select: none;
    /* mozilla browsers */
    -khtml-user-select: none;
    /* webkit (konqueror) browsers */
    -ms-user-select: none;
    /* IE10+ */
}


/* checkbox aspect */

[type=checkbox]+label:before,
[type=checkbox]+label:after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 1;
    -webkit-transition: 0.2s;
    transition: 0.2s;
}


/* Unchecked styles */

[type=checkbox]:not(:checked)+label:before {
    top: 4px;
    width: 19px;
    height: 19px;
    border: 2px solid #CED2DE;
    border-radius: 4px;
}

@media screen and (max-width: 992px) {
    [type=checkbox]:not(:checked)+label:before {
        top: 2px;
    }
}

[type=checkbox]:not(:checked)+label:after {
    top: 4px;
    width: 19px;
    height: 19px;
    z-index: 0;
}

@media screen and (max-width: 992px) {
    [type=checkbox]:not(:checked)+label:after {
        top: 2px;
    }
}


/* Checked styles */

[type=checkbox]:checked+label:before {
    top: 2px;
    width: 6px;
    height: 12px;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid #1a1a1a;
    border-bottom: 1px solid #1a1a1a;
    -webkit-transform: rotateZ(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 20% 40%;
    transform-origin: 100% 100%;
}

[type=checkbox]:checked+label:after {
    top: 4px;
    width: 19px;
    height: 19px;
    border: 1px solid #1a1a1a;
    z-index: 0;
    background: #1a1a1a;
    border-radius: 4px;
}


/* disabled checkbox */

[type=checkbox]:disabled:not(:checked)+label:before,
[type=checkbox]:disabled:checked+label:before {
    top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #444;
    width: 19px;
    height: 19px;
    border: 3px solid #444;
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
}

[type=checkbox]:disabled+label {
    color: #555;
}

[type=checkbox]:disabled:not(:checked)+label:hover:before {
    border-color: #01C8E7;
}

[type=checkbox]:checked+label:before {
    top: 7px;
    left: 2px;
    width: 6px;
    height: 12px;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: rotateZ(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 20% 40%;
    transform-origin: 100% 100%;
}

.hw-30 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
}

.mini {
    /* Unchecked styles */
    /* Checked styles */
}

.mini [type=checkbox]:not(:checked)+label,
.mini [type=checkbox]:checked+label {
    padding-left: 19px;
    margin: 0;
}

.mini [type=checkbox]+label:before,
.mini [type=checkbox]+label:after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 1;
    -webkit-transition: 0s;
    transition: 0s;
}

.mini [type=checkbox]:not(:checked)+label:before {
    top: 4px;
    width: 19px;
    height: 19px;
    border: none;
}

.mini [type=checkbox]:not(:checked)+label:after {
    top: 0px;
    width: 19px;
    height: 19px;
    border: 0px solid #1a1a1a;
    z-index: 0;
    content: "+";
    text-align: center;
    font-size: 21px;
}

.mini [type=checkbox]:checked+label:before {
    top: 50%;
    left: 4.5px;
    width: 9px;
    height: 2px;
    background: white;
    border: 0px;
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
    -webkit-transform-origin: unset;
    transform-origin: unset;
}

.mini [type=checkbox]:checked+label:after {
    top: 4px;
    width: 19px;
    height: 19px;
    border: 1px solid #1a1a1a;
    z-index: 0;
    background: #1a1a1a;
    border-radius: 50%;
}

.mini.inss [type=checkbox]:not(:checked)+label:before {
    top: 4px;
    width: 19px;
    height: 19px;
    border: none;
    background: transparent;
    border-radius: 2px;
}

.mini.inss [type=checkbox]:not(:checked)+label:after {
    z-index: 5;
}

.cus_switch {
    /* Rounded sliders */
}

@media screen and (max-width: 767px) {
    .cus_switch {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
        margin-top: 15px !important;
    }
}

.cus_switch b {
    padding-right: 6px;
}

.cus_switch .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 22px;
}

.cus_switch .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.cus_switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border: solid 2px #000;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.cus_switch .slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 2px;
    background-color: #000;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.cus_switch input:checked+.slider {
    background-color: #164EA2;
    border: solid 2px #164EA2;
}

.cus_switch input:focus+.slider {
    -webkit-box-shadow: 0 0 1px #164EA2;
    box-shadow: 0 0 1px #164EA2;
}

.cus_switch input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    background: white;
    transform: translateX(26px);
}

.cus_switch .slider.round {
    border-radius: 34px;
}

.cus_switch .slider.round:before {
    border-radius: 50%;
}

.owl-nav {
    text-align: center;
}

.owl-nav button {
    background: #1a1a1a !important;
    margin: 0px 5px;
    width: 25px;
    height: 25px;
    border-radius: 4px;
    color: white !important;
    font-size: 12px !important;
}

.owl-nav button i:before {
    color: white;
}

input,
textarea {
    -webkit-appearance: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    font-weight: 500;
    color: #1a1a1a;
}

body {
    font-family: "Inter";
    font-weight: normal;
    counter-reset: my-sec-counter;
}

html {
    font-family: "Inter";
    font-size: 16px;
    scroll-behavior: smooth;
}

@media screen and (max-width: 1599px) {
    html {
        font-size: 14px;
    }
}

@media screen and (max-width: 1439px) {
    html {
        font-size: 14px;
    }
}

@media screen and (max-width: 1199px) {
    html {
        font-size: 13px;
    }
}

@media screen and (max-width: 991px) {
    html {
        font-size: 12px;
    }
}

@media screen and (max-width: 767px) {
    html {
        font-size: 11px;
    }
}

body {
    font-family: "Inter";
    font-size: 1rem;
    font-weight: normal;
    color: #636363;
    line-height: normal;
    position: relative;
}

p {
    line-height: 1.5rem;
}

.dropdown-toggle {
    -webkit-appearance: none;
}

a[type=button] {
    -webkit-appearance: none;
}


/* 
.container-fluid {
    padding: 0 2%;
}
*/


/* width */

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}


/* Track */

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px grey;
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #164ea2;
    border-radius: 5px;
}

a:hover {
    color: inherit;
    text-decoration: none;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #164ea2;
}

.container_lg {
    margin: 0 auto;
}

@media screen and (min-width: 992px) {
    .container_lg {
        width: 960px;
    }
}

@media screen and (min-width: 1200px) {
    .container_lg {
        width: 1170px;
    }
}

@media screen and (min-width: 1600px) {
    .container_lg {
        width: 1330px;
    }
}

@media screen and (min-width: 1920px) {
    .container_lg {
        width: 1650px;
    }
}

.cus_border {
    border-bottom: 1px solid #cdcdcd;
}

.cus_border23 {
    position: relative;
}

.cus_border23::after {
    width: 90%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    background: #cdcdcd;
}

.bb-1 {
    border-bottom: solid 1px #ededed !important;
}

.is-hidden {
    opacity: 0;
    -webkit-transition: opacity 0.2s, -webkit-transform 0.5s;
    transition: opacity 0.2s, -webkit-transform 0.5s;
    transition: transform 0.5s, opacity 0.2s;
    transition: transform 0.5s, opacity 0.2s, -webkit-transform 0.5s;
    pointer-events: none;
}

.b_bg {
    background: #f9f9fb;
}

.top {
    padding: 2% 0;
    position: relative;
}

.top.top_1 {
    padding: 5% 0%;
}

.top.reg_2_page:before {
    width: 82%;
    height: 100%;
}

@media screen and (max-width: 992px) {
    .top.reg_2_page:before {
        width: 74%;
    }
}

@media screen and (max-width: 767px) {
    .top.reg_2_page .logo {
        text-align: center;
    }
}

@media screen and (max-width: 767px) {
    .top.reg_2_page .logo img {
        width: 30% !important;
    }
}

@media screen and (max-width: 480px) {
    .top.reg_2_page .logo img {
        width: 55% !important;
    }
}

.top .reg_left {
    background: white;
    padding: 25px;
    border-radius: 12px;
    -webkit-box-shadow: 0 11px 20px -6px rgba(0, 0, 0, 0.16);
    box-shadow: 0 11px 20px -6px rgba(0, 0, 0, 0.16);
}

.top .reg_left .f_head {
    margin-bottom: 30px;
}

.top .reg_left .f_head p {
    color: #767a8a;
    font-size: 16px;
}

.top .reg_left .f_head p a {
    color: #000;
    font-weight: 500;
}

.top .reg_left ul#myTab {
    background: rgba(25, 93, 196, 0.1);
    border-radius: 4px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 5px;
    border: none;
    margin-bottom: 20px;
}

.top .reg_left ul#myTab li {
    min-width: 50%;
    text-align: center;
}

.top .reg_left ul#myTab li a {
    font-size: 14px;
    color: #292f49;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
}

.top .reg_left ul#myTab li a.active {
    border: none !important;
    border-radius: 4px !important;
    color: #003b75;
}

.top .reg_left .j_form .reg_btn a {
    margin: 0 10px 0 0;
}

.top .reg_left .j_form .reg_btn a.active {
    background: #292f49;
    color: white;
}

.top .reg_left .j_form .reg_btn a.active:hover {
    background: transparent;
    color: #292f49;
}

.top .reg_left .plan_box {
    border: solid 2px #ededed;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
}

.top .reg_left .plan_box.active {
    border: solid 2px #292f49;
}

.top .reg_left .plan_box .plan_box_left .plan_img {
    display: block;
    width: 48px;
    margin-right: 15px;
    height: 48px;
    border-radius: 4px;
    background: #f3f3f3;
}

.top .reg_left .plan_box .plan_box_left .plan_date p span {
    margin: 0 5px 0 0;
}

.top .reg_left .plan_box .plan_box_right h4 span {
    margin: 0 0 0 35px;
    display: inline-block;
    min-width: 65px;
    position: relative;
    font-weight: 400;
}

.top .reg_left .plan_box .plan_box_right h4 span::before {
    position: absolute;
    left: 0;
    top: 0;
    content: "/";
}

@media screen and (max-width: 767px) {
    .top .reg_left .plan_box .plan_box_right h4 span {
        margin: 0 0 0 15px;
        min-width: 45px;
    }
}

.top .jer_reg_form .login_btn .active {
    background: #292f49;
    color: white;
}

.top.whites::before {
    background: white;
}

.card_head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 30px;
    border-bottom: solid 1px #e1e3ec;
    padding: 0 0 10px;
}

.card_head .card_head_left h2 {
    font-size: 32px;
    color: #090f1d;
}

@media screen and (max-width: 767px) {
    .card_head .card_head_left h2 {
        font-size: 20px;
    }
}

.card_head p {
    font-size: 16px;
    color: #767a8a;
}

.j_l {
    position: fixed;
    background: white;
    overflow: auto;
    height: 100%;
    left: 0;
    width: 17%;
    top: 0;
    padding: 15px 0;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
}

@media screen and (max-width: 991px) {
    .j_l {
        width: 24%;
    }
}

@media screen and (max-width: 767px) {
    .j_l {
        position: inherit;
        margin-bottom: 30px;
        height: auto;
        width: 100%;
    }
}

.j_l .logo {
    border-bottom: solid 2px #E1E3EC;
    padding: 15px 0px 20px !important;
    text-align: center;
}

.j_l .logo img {
    width: 70% !important;
}

.card0 {
    background-color: #f5f5f5;
    border-radius: 8px;
    z-index: 0;
}

.card00 {
    z-index: 0;
}

.card1 {
    z-index: 0;
    border-right: 1px solid #f5f5f5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.sidemenu_footer {
    padding-top: 90%;
    padding-left: 30px;
}

@media screen and (max-width: 991px) {
    .sidemenu_footer {
        padding-top: 50%;
    }
}

@media screen and (max-width: 767px) {
    .sidemenu_footer {
        padding-top: 0%;
    }
}


/* .card2 {
  display: none;
} */

.card2.show {
    display: block;
    padding-left: 30px;
}

@media screen and (max-width: 992px) {
    .card2.show {
        padding-left: 0;
    }
}

.sidemenu_footer .que {
    font-size: 16px;
    color: #767a8a;
}

#progressbar {
    margin: 0;
    padding: 0;
    padding-left: 25px;
}

#progressbar li {
    list-style-type: none;
    font-size: 8px;
    font-weight: 400;
    margin-bottom: 20px;
}

#progressbar li.visited span {
    background: black;
}

#progressbar li span {
    display: block;
    width: 4px;
    height: 4px;
    background: #292F49;
    border-radius: 2px;
    margin-bottom: 10px;
    margin-left: 12px;
}

#progressbar .step0:before {
    counter-increment: my-sec-counter;
    content: counter(my-sec-counter);
    color: white;
}

#progressbar li:before {
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: block;
    font-size: 1rem;
    background: #767a8a;
    border: 2px solid #767a8a;
    border-radius: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: white;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 30px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    content: "1";
}

#progressbar li.active.visited:before {
    background: #090f1d;
    font-family: FontAwesome;
    content: "";
    color: #fff;
    background-image: url(../images/svg/check-white.svg);
    background-repeat: no-repeat;
    background-position: center;
    border: 2px solid #090f1d;
}

#progressbar.edit li.active:before {
    background: #090f1d;
    font-family: FontAwesome;
    content: "1";
    color: #fff;
    border: 2px solid #090f1d;
}

#progressbar li.active:before {
    background: #164EA2;
    font-family: FontAwesome;
    color: #fff;
    border: 2px solid #164EA2;
}

#progressbar.edit .step0:before {
    counter-increment: my-sec-counter;
    content: counter(my-sec-counter) !important;
    color: #090f1d;
}

#progressbar li.active h4 {
    font-weight: 500;
}

.tick {
    width: 100px;
    height: 100px;
}

.prev {
    display: block;
    position: absolute;
    left: 40px;
    top: 20px;
    cursor: pointer;
}

.prev:hover {
    color: #d50000 !important;
}

@media screen and (max-width: 912px) {
    .card00 {
        padding-top: 30px;
    }
    .card1 {
        border: none;
    }
    .card2 {
        border-bottom: 1px solid #f5f5f5;
        margin-bottom: 25px;
    }
    #progressbar {
        left: 20px;
    }
}

.choose_file .c_btn {
    background: #164ea2;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
}

.pay_methor {
    background: white;
    padding: 15px;
    border: solid 2px #ced2de;
    border-radius: 4px;
}

.device_d {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: solid 1px #ccc;
    padding: 2px 10px;
    border-radius: 30px;
}

.device_d span {
    margin: 0 5px 0 0;
}

.device_d p {
    margin: 0;
    font-size: 13px;
    color: #767a8a;
}

.sub_plan_box {
    background: white;
    padding: 15px;
    border: solid 1px #ced2de;
    border-radius: 8px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 20px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 50px 10px 30px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.sub_plan_box.active_new_plan {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
}

.sub_plan_box.active_new_plan h4 {
    background: #164ea2;
    color: white;
}

.sub_plan_box.active_new_plan:hover h4 {
    color: black;
}

.sub_plan_box:hover {
    background: #164ea2;
}

.sub_plan_box:hover .device_d span:before {
    color: white;
}

.sub_plan_box:hover h3 {
    color: white !important;
}

.sub_plan_box:hover h4 {
    background: white;
}

.sub_plan_box:hover p {
    color: white;
}

.sub_plan_box:hover p span {
    color: white;
}

.sub_plan_box.sub_active {
    background: #164ea2;
}

.sub_plan_box.sub_active .device_d span:before {
    color: white;
}

.sub_plan_box.sub_active h3 {
    color: white !important;
}

.sub_plan_box.sub_active h4 {
    background: white;
}

.sub_plan_box.sub_active p {
    color: white;
    font-weight: 400;
}

.sub_plan_box.sub_active p span {
    color: white;
    font-weight: 600;
}

.sub_plan_box.sub_active p.save_d {
    font-weight: 200 !important;
}

.sub_plan_box h3 {
    font-size: 24px;
    color: #000;
    margin-bottom: 15px;
}

.sub_plan_box h3.Trial {
    color: #767a8a;
}

.sub_plan_box h3.Light {
    color: #648eb9;
}

.sub_plan_box h3.Plus {
    color: #ad702c;
}

.sub_plan_box h3.Pro {
    color: #164ea2;
}

.sub_plan_box h4 {
    border: solid 2px #ced2de;
    padding: 5px 15px;
    margin: 15px 0;
    font-size: 14px;
    color: #090f1d;
    border-radius: 6px;
    width: 60%;
}

@media screen and (max-width: 1025px) {
    .sub_plan_box h4 {
        font-size: 14px;
        padding: 5px 10px;
    }
}

.sub_plan_box p {
    font-size: 12px;
    color: #767a8a;
    font-weight: 600;
    margin: 0;
}

.sub_plan_box p span {
    color: #236653;
}

.credit_card_box {
    background: white;
    padding: 15px;
    border: solid 1px #ced2de;
    border-radius: 8px;
    padding: 15px;
}

.credit_card_box .card_name p {
    font-size: 12px;
    color: #767a8a;
    margin: 0 0 3px;
    font-weight: 600;
}

.credit_card_box .card_name h3 {
    font-size: 18px;
    font-weight: 600;
    color: #090f1d;
}

.credit_card_box .form-control {
    color: #292f49;
    font-weight: 600;
    height: 35px;
}

.credit_card_box .select2-container .select2-selection--single {
    height: 35px;
}

.credit_card_box .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 33px !important;
    height: 35px;
}

.credit_card_box .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: -8px;
}

.confrim_box {
    text-align: center;
    padding: 20px;
    margin-top: 12%;
    background: white;
    -webkit-box-shadow: 0 7px 15px -7px rgba(0, 0, 0, 0.16);
    box-shadow: 0 7px 15px -7px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
}

.confrim_box .con_1 .co_t {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto 20px;
    background: white;
    border-radius: 50%;
    display: flex;
    color: #767a8a;
    width: 40px;
    font-size: 20px;
    height: 40px;
    border: solid 2px #767a8a;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.confrim_box .con_1 .co_t i::before {
    color: #767a8a;
}

.confrim_box .con_1 p {
    color: #767a8a;
    margin: 30px 0;
    position: relative;
    font-size: 15px;
    padding: 0 0 25px;
}

.confrim_box .con_1 p:after {
    position: absolute;
    content: "";
    background: #E1E3EC;
    width: 80px;
    height: 2px;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
}

.confrim_box .con_2 .email span {
    background: rgba(25, 93, 196, 0.12);
    padding: 10px 15px;
    color: #090f1d;
    font-size: 13px;
    border-radius: 30px;
    margin-right: 7px;
}

.confrim_box .con_2 p {
    color: #767a8a;
    margin: 35px 0;
    font-size: 15px;
}

.confrim_box .con_2 p span {
    color: #090f1d;
}

@media screen and (max-width: 767px) {
    .card1 {
        border: none;
        margin-left: 0;
    }
    #progressbar {
        left: 0px;
        position: inherit;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        width: 100%;
    }
    #progressbar li {
        margin: 0 3px 16px;
    }
    #progressbar li span {
        display: none;
    }
}

.register-btn {
    background: #164ea2;
    color: white;
    font-size: 1.25rem;
    font-weight: 600;
    border: solid 2px #164ea2;
    transition: 0.3s linear all;
}

.register-btn:hover {
    background: #164ea2;
    border: solid 2px #164ea2;
    color: white;
}

.register-btn.op30 {
    background: rgba(26, 26, 26, 0.3);
    color: white;
    padding-left: 15px;
    padding-right: 15px;
}

.register-btn.op30:hover {
    border-color: transparent;
}

.flip_card .card {
    width: 100%;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    border-radius: 8px;
    margin: 0 0 60px;
    background: transparent;
    border: none;
    -webkit-transform-origin: 50% 50%;
}

.flip_card .card .front {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: bold;
    position: absolute;
    z-index: 55;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip_card .card .back .credit_card_box {
    padding: 0;
    height: 100%;
}

.flip_card .card .back .credit_card_box .divider {
    display: block;
    width: 100%;
    background: #9ebbd8;
    margin-top: 30px;
    margin-bottom: 30px;
    height: 50px;
}

.flip_card .card .back .credit_card_box label {
    text-transform: uppercase;
}

.flip_card .card.flipped {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.flip_card .card.flipped .back {
    -webkit-transform: inherit;
    transform: inherit;
}

.flip_card .flip_btn {
    background: transparent;
    border: none;
    outline: none;
    float: right;
    margin-bottom: 16px;
    font-weight: 600;
    text-transform: capitalize;
}

.flip_card .flip_btn span.icon-arrow_left {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    display: inline-block;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltiptext {
    visibility: hidden;
    width: 85px;
    padding: 6px 0 !important;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;
}

.tooltiptext:before {
    position: absolute;
    content: "";
    border: solid #1a1a1a;
    border-width: 0 6px 6px 0;
    display: inline-block;
    padding: 3px;
    top: -4px;
    /* right: 0; */
    left: 50%;
    margin: 0 auto;
    /* width: 100%; */
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
}

.CardBlock:hover .tooltiptext {
    visibility: visible;
}

.form-control {
    background: white;
}

.why_cavehher .new_why {
    max-height: 500px;
    overflow: auto;
}

.why_cavehher .new_why::-webkit-scrollbar {
    display: none;
}

.why_cavehher .new_why .largest_sec {
    text-align: center;
    margin-bottom: 80px;
}

.why_cavehher .new_why .largest_sec .l_1 {
    text-align: center;
    position: relative;
}

@media screen and (max-width: 767px) {
    .why_cavehher .new_why .largest_sec .l_1 {
        margin-bottom: 20px;
    }
}

.why_cavehher .new_why .largest_sec .l_1.b_a:before {
    position: absolute;
    content: "";
    background: #ced2de;
    width: 2px;
    height: 50px;
    right: 0;
    top: 20%;
}

@media screen and (max-width: 767px) {
    .why_cavehher .new_why .largest_sec .l_1.b_a:before {
        display: none;
    }
}

.why_cavehher .new_why .largest_sec .l_1 p {
    color: #003b75;
    padding-top: 10px;
    max-width: 150px;
    margin: 0px auto;
    font-weight: 600;
}

.why_cavehher .new_why .largest_sec .w_img {
    margin-top: 30px;
}

.why_cavehher .new_why .largest_sec .w_img img {
    width: 100%;
}

.why_cavehher .new_why .home_pro_slider .owl-carousel .item {
    border: none;
    opacity: 0.5;
    padding: 0 20px;
    min-height: 346px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.why_cavehher .new_why .home_pro_slider .owl-carousel .item .hover_btn {
    display: none;
}

.why_cavehher .new_why .home_pro_slider .owl-carousel .item .hover_btn .btn {
    min-width: auto;
    font-size: 14px;
    padding: 10px 5px;
}

@media screen and (max-width: 992px) {
    .why_cavehher .new_why .home_pro_slider .owl-carousel .item .hover_btn .btn {
        font-size: 12px;
    }
}

.why_cavehher .new_why .home_pro_slider .owl-carousel .item:hover {
    background: white;
    opacity: 1;
    -webkit-box-shadow: 0 -8px 20px -10px rgba(0, 0, 0, 0.16);
    box-shadow: 0 -8px 20px -10px rgba(0, 0, 0, 0.16);
    width: 100%;
    border-radius: 4px;
}

.why_cavehher .new_why .home_pro_slider .owl-carousel .item:hover .hover_btn {
    display: block;
}

.why_cavehher .new_why .home_pro_slider .owl-carousel .owl-dots {
    display: none;
}

.why_cavehher .new_why .home_pro_slider .owl-carousel .owl-nav {
    display: none;
}

.why_cavehher .new_why .home_pro_slider .owl-carousel .owl-nav button {
    position: absolute;
    top: 32%;
}

.why_cavehher .new_why .home_pro_slider .owl-carousel .owl-nav button.owl-prev {
    left: 0;
}

.why_cavehher .new_why .home_pro_slider .owl-carousel .owl-nav button.owl-next {
    right: 0;
}

.seach_filter {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.payement_info {
    font-size: 16px;
    color: #003b75;
    font-weight: 500;
}

.seller_info_1 h4 {
    word-break: break-word;
}

.seller_info_1 a {
    color: #090F1D;
}

.bet_arrow {
    margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
    .bet_arrow span {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        display: block;
    }
}

.form-group.chkbx u {
    font-weight: 500;
}

p.exe {
    text-align: left;
    color: #767a8a;
    font-weight: 600;
}

@media screen and (max-width: 992px) {
    .container {
        max-width: 100%;
    }
}

.Loginbox {
    background: white;
    border-radius: 16px;
}

.Loginbox .acntHelp {
    color: #292f49;
}

.Loginbox .acntHelp a {
    color: #292f49;
    text-decoration: underline;
}

@media screen and (max-width: 767px) {
    .Loginbox {
        margin-left: 15px;
        margin-right: 15px;
        width: calc(100% - 30px);
        -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(100% - 30px);
        flex: 0 0 calc(100% - 30px);
    }
}

.bg-semis {
    background: #003b75;
}

.bg-semis .rightAlign {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.bg-semis .searchs {
    position: absolute;
    right: 15px;
    top: 13px;
}

.bg-semis .u-img {
    width: 35px;
    height: 35px;
    display: inline-block;
    border-radius: 50%;
    margin-top: 2px;
}

.bg-semis button {
    border-radius: 5px;
    font-size: 13px;
    margin-top: 2px;
}

.bg-semis .options {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-top: 2px;
    background: transparent;
    position: relative;
    text-align: center;
    display: inline-block;
    line-height: 30px;
    border: solid 2px #4a75a1;
}

.bg-semis .options.op_btn {
    width: fit-content;
    border-radius: 28px;
    color: white;
    padding-right: 6px;
    padding-left: 6px;
}

.bg-semis .options.op_btn img {
    margin-right: 6px;
}

.bg-semis .options span {
    position: absolute;
    right: -3px;
    top: -3px;
    border-radius: 50%;
    background: #ffdaa4;
    border: 1px solid #ffdaa4;
    line-height: 13px;
    font-size: 9px;
    width: 15px;
    color: #292f49;
    font-weight: bold;
    height: 15px;
}

.bg-semis button {
    height: 35px;
}

.bg-semis .form-control {
    height: 40px;
}

.bg-semis .navbar-nav li {
    padding: 10px 15px;
}

@media screen and (max-width: 767px) {
    .bg-semis .navbar-nav li {
        padding: 10px 0px;
    }
}

.bg-semis .navbar-nav li a {
    color: #9ebbd8;
    font-size: 14px;
    font-weight: 400;
}

.bg-semis .navbar-nav li a.active {
    color: white;
}

.bg-semis .navbar-nav li:first-child {
    padding-left: 0;
}

.bg-semis .navbar-nav li.active a {
    font-weight: 600 !important;
}

.bg-semis .pre-hader-bg .container-fluid {
    border-bottom: solid 1px rgba(158, 187, 216, 0.2) !important;
}

@media screen and (max-width: 480px) {
    .bg-semis .pre-hader-bg .form-group {
        margin: 15px 0 !important;
    }
}

@media screen and (max-width: 767px) {
    .bg-semis .pre-hader-bg .text-right {
        text-align: center !important;
    }
}

.breadcrub {
    margin: 0;
    padding: 10px 15px;
    border-bottom: solid 1px #e7e7e7;
}

.breadcrub li {
    display: inline-block;
    padding-right: 10px;
    color: #090F1D;
}

.navbar-brand {
    vertical-align: top;
    margin-left: 10px;
    margin-top: 10px;
    font-weight: bold;
    color: white;
}

.icon-bar {
    width: 20px;
    height: 2px;
    display: block;
    background: #fff;
    margin-top: 4px;
    margin-bottom: 2px;
}

.conPart h2 {
    font-size: 20px;
    color: #1a1a1a;
    font-weight: 400;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .conPart h2 {
        font-size: 15px;
    }
}

.conPart .lefts ul {
    margin: 0px;
    padding: 0;
    list-style: none;
}

.conPart .lefts ul li {
    font-size: 14px;
    font-weight: 400;
    line-height: 36px;
}

.conPart .lefts ul li.active a {
    color: #090f1d;
}

.conPart .lefts ul li a {
    color: #767a8a;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.conPart .lefts ul li a img {
    margin: 0 0 0 5px;
    display: none;
}

.conPart .lefts ul li.active {
    font-weight: 600;
}

.conPart .lefts ul li.active img {
    display: inline-block;
}

.conPart .lefts ul.mesList li {
    border: solid 2px #ededed;
    border-radius: 5px;
    padding: 10px;
    float: left;
    width: 100%;
    margin-bottom: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
}

.conPart .lefts ul.mesList li img {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    float: left;
    margin-right: 15px;
}

.conPart .lefts ul.mesList li h4 {
    font-size: 14px;
    color: #1a1a1a;
    font-weight: 600;
    margin: 4px 20px 5px;
}


.conPart .lefts ul.mesList li.active {
    border-color: #164ea2;
    font-weight: 400;
    background: #164ea2;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
}

.conPart .lefts ul.mesList li.active h4 {
    color: white;
}

.conPart .lefts ul.mesList li.active span {
    color: white;
}

.conPart .lefts ul.mesList li:hover {
    border-color: #164ea2;
    font-weight: 400;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    background: #164ea2;
    cursor: pointer;
}

.conPart .lefts ul.mesList li:hover h4 {
    color: white;
}

.conPart .lefts ul.mesList li:hover span {
    color: white;
}

@media screen and (max-width: 768px) and (max-width: 992px) {
    .conPart .lefts ul.mesList li {
        text-align: center;
    }
    .conPart .lefts ul.mesList li img {
        float: none;
    }
}

@media screen and (max-width: 767px) {
    .conPart .lefts {
        padding-left: 15px !important;
    }
}

@media screen and (max-width: 768px) and (max-width: 992px) {
    .conPart .lefts {
        padding-left: 15px !important;
    }
}

.conPart .titles {
    border-bottom: solid 1px #dadada;
}

.conPart .titles h2 {
    line-height: 34px;
    font-weight: 600;
}

.conPart .titles .hides {
    text-align: right;
}

.conPart .titles .hides .btn-black.btn-delete {
    background: #b43434;
    border-color: #b43434;
    color: white;
}

.conPart .titles .hides .btn-black.btn-delete span::before {
    color: white;
}

.conPart .titles .form-inline {
    position: relative;
    display: inline-block;
    vertical-align: top;
}

.conPart .titles .form-inline input {
    height: 34px;
    font-size: 12px;
    padding-left: 40px;
    min-width: 250px;
}

.conPart .titles .form-inline img {
    position: absolute;
    left: 11px;
    top: 9px;
}

.conPart .titles .form-inline label {
    font-size: 12px;
    font-weight: 600;
    margin-left: 10px;
    overflow: inherit;
    padding-left: 25px;
    margin-top: -1px;
    margin-bottom: 0;
}

.conPart .titles .form-inline.sort {
    font-size: 12px;
    border: solid 1px #cdcdcd;
    line-height: 34px;
    border-radius: 4px;
    margin-left: 5px;
    padding-right: 8px;
}

.conPart .titles .form-inline.sort label {
    display: inline-block;
    padding: 0;
    margin-top: 0;
    font-weight: 600;
}

.conPart .titles .form-inline button {
    line-height: 30px;
    font-size: 12px;
    padding-left: 8px;
    margin-right: 8px;
    border-radius: 4px;
    margin-left: 5px;
    height: 35px;
}

@media screen and (max-width: 1025px) {
    .conPart .titles .form-inline {
        display: block;
        text-align: right !important;
        margin-bottom: 10px !important;
    }
}

@media screen and (max-width: 480px) {
    .conPart .titles .form-inline {
        display: block;
        text-align: left !important;
        margin-top: 20px;
        margin-bottom: 10px !important;
    }
}

.conPart .titles.nbrd {
    border-bottom: 0;
}

.conPart .tbles {
    padding: 20px 0;
    -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: auto;
}

.conPart .tbles th {
    font-weight: 400;
    font-size: 12px;
    color: #767a8a;
    font-weight: 600;
    padding-bottom: 8px;
    border-bottom: solid 1px #e1e3ec;
}

@media screen and (max-width: 767px) {
    .conPart .tbles th {
        white-space: nowrap;
    }
}

.conPart .tbles th:nth-child(1) {
    /* color: red; */
}

.conPart .tbles th:nth-child(1) label {
    color: #767A8A;
}

.conPart .tbles th:last-child {
    padding-right: 10px;
}

.conPart .tbles td {
    font-weight: 400;
    font-size: 12px;
    color: #292f49;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px #e1e3ec;
}

.conPart .tbles td:last-child {
    padding-right: 10px;
}

.conPart .tbles td.hrd {
    text-decoration: underline;
}

.conPart .tbles tr:last-child td {
    border-bottom: 0px;
}

.conPart .tbles .text-right {
    position: relative;
}

.conPart .tbles .more {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.9);
    top: 0px;
    bottom: 0px;
    right: 0;
    display: none;
    text-align: right;
}

.conPart .tbles .more span {
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    border: solid 1px rgba(41, 47, 73, 0.4);
    margin-top: 8px;
    background: white;
    line-height: 26px;
    border-radius: 3px;
}

.conPart .tbles .more span img {
    width: 3px;
}

.conPart .tbles .more ul {
    margin: 0;
    padding: 10px;
    border: solid 1px rgba(41, 47, 73, 0.4);
    border-radius: 8px;
    width: 200px;
    list-style: none;
    background: white;
    position: absolute;
    right: 0;
    display: none;
    top: 40px;
    z-index: 3;
}

.conPart .tbles .more ul li {
    display: block;
    text-align: left;
    border-bottom: none;
    /* &:last-child {
      border: 0;
  }*/
}

.conPart .tbles .more ul li a {
    display: block;
    padding: 10px 0 0px;
    color: #292f49;
}

.conPart .tbles .more ul li a.delete {
    color: #b43434;
}

.conPart .tbles .more ul li a.delete span {
    width: auto;
    height: auto;
    border: none;
}

.conPart .tbles tr:hover {
    cursor: pointer;
}

.conPart .tbles tr:hover td .more {
    display: block;
    cursor: pointer;
}

.conPart .tbles tr:hover td .more:hover ul {
    display: block;
}

.m-t-6 {
    margin-top: 6px;
}

h3.forms {
    font-size: 20px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 30px;
    color: #000000;
}

h3.forms p {
    font-size: 13px;
    line-height: 25px;
    color: #6a6a6a;
    font-weight: 400;
}

h3.forms p span {
    color: #090f1d;
    font-weight: 500;
}

h3.forms.toBrdr {
    padding-top: 15px;
    border-top: 1px solid rgba(218, 218, 218, 0.5);
}

.imgEdits h4 {
    font-size: 18px;
    color: #000000;
    margin-top: 15px;
}

.imgEdits p img {
    margin-right: 5px;
}

.imgEdits p.f-18px {
    font-size: 16px;
    font-weight: 500;
    color: #1a1a1a;
}

.imgEdits .imgBlock {
    position: relative;
    padding: 0;
    border-radius: 5px;
}

@media screen and (max-width: 767px) {
    .imgEdits .imgBlock {
        margin: 0 15px;
    }
}

.imgEdits .imgBlock img {
    border-radius: 5px;
    height: 100%;
}

.imgEdits .imgBlock .hover {
    position: absolute;
    left: 0px;
    right: 0px;
    border-radius: 5px;
    top: 0;
    bottom: 0;
    display: none;
    background: rgba(0, 0, 0, 0.3);
}

.imgEdits .imgBlock .hover img {
    margin: 15px;
    height: auto;
}

.imgEdits .imgBlock .hover .uploads {
    width: 120px;
    height: 120px;
    background: url(../images/svg/circles.svg) no-repeat;
    background-size: contain;
    margin: 40px auto;
    text-align: center;
    line-height: 120px;
    color: white;
    font-size: 35px;
}

.imgEdits .imgBlock .hover2 {
    position: absolute;
    left: 0px;
    right: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 5px;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}

.imgEdits .imgBlock .hover2 .uploads {
    width: 120px;
    height: 120px;
    background: url(../images/svg/circles.svg) no-repeat;
    background-size: contain;
    margin: 40px auto;
    text-align: center;
    line-height: 120px;
    color: white;
    font-size: 35px;
}

.imgEdits .imgBlock:hover .hover {
    display: block;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    color: white;
}

.imgEdits .moreUpload {
    background: #fff;
    border: 3px dashed #E0E2EA;
    min-height: 120px;
    border-radius: 4px;
    text-align: center;
    line-height: 120px;
    font-size: 16px;
    font-weight: 500;
    color: #1a1a1a;
    overflow: hidden;
    position: relative;
}

.imgEdits .moreUpload h5 {
    color: #090f1d;
    font-size: 18px;
}

.imgEdits .moreUpload p {
    color: #767a8a;
    font-size: 14px;
}

.imgEdits .moreUpload span.icon-close_1 {
    color: #b43434;
    font-size: 16px;
}

.imgEdits .moreUpload input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
}

.imgEdits .uploadDetail {
    background: #f7f7f7;
    border: 1px dashed #a6a6a6;
    min-height: 122px;
    border-radius: 4px;
    text-align: left;
    line-height: 120px;
    font-size: 16px;
    padding: 10px 15px;
    font-weight: 500;
    color: #1a1a1a;
    overflow: hidden;
    position: relative;
    top: 0px;
}

.imgEdits .uploadDetail h4 {
    font-weight: 500;
    color: black;
    margin-bottom: 15px;
}

.imgEdits .uploadDetail p {
    margin: 0;
    font-size: 13px;
    color: #6a6a6a;
}

.imgEdits .uploadDetail p.bold {
    color: #1a1a1a;
    font-weight: 600;
}

.actions .col-md-6 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.actions .col-lg-6 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.actions .btns {
    background: #164ea2;
    border-radius: 5px;
    color: white;
    padding: 15px 30px;
    border: solid 2px #164ea2;
    min-width: 180px;
    font-weight: 600;
    margin-right: 20px;
}

.actions .btns.btn-remove {
    background: transparent;
    color: #b43434;
    border: solid 1px #ced2de;
}

@media screen and (max-width: 992px) {
    .actions .btns {
        min-width: 48%;
        padding: 15px 12px;
    }
}

@media screen and (max-width: 767px) {
    .actions .btns {
        min-width: 49%;
    }
}

.actions .btns img {
    margin-left: 8px;
}

.actions .btns.outline {
    background: white;
    color: #1a1a1a;
    border: solid 1px #ced2de;
}

.actions .btns.outline.red {
    color: #b43434;
}

.actions .btns.greys {
    background: #ced2de;
    border: solid 2px #ced2de;
    margin-right: 10px;
    color: #767A8A;
}

.actions .btns.b-op {
    border: solid 2px #cdcdcd;
}

.actions .btns.hblack:hover {
    background: #164ea2;
    color: white;
    border-color: #164ea2;
}

.actions.brdr-top {
    border-top: solid 1px rgba(218, 218, 218, 0.5);
}

.bblck {
    padding-top: 15px;
    border-top: solid 1px rgba(218, 218, 218, 0.5);
}

.separt {
    padding-top: 3px;
    font-size: 12px;
}

.brdr-top {
    border-top: solid 1px rgba(218, 218, 218, 0.5);
}

.brdr-btms {
    border-bottom: solid 1px rgba(218, 218, 218, 0.5);
}

.paybox {
    border: solid 2px rgba(218, 218, 218, 0.5);
    border-radius: 8px;
}

.paybox .leftImg {
    width: 88px;
    height: 88px;
    background: #f3f3f3;
    border-radius: 4px;
}

.paybox .rightCon .p_s_top .Light_color {
    color: #648eb9;
}

.paybox .rightCon .p_s_top p {
    font-weight: 600;
}

.paybox .rightCon .p_s_top p span {
    font-weight: 500;
    color: #767a8a;
}

.paybox .rightCon p {
    display: inline-block;
    font-size: 12px;
    color: #292f49;
    margin-bottom: 0px;
}

.paybox .rightCon p.inBrdr {
    border: solid 1px rgba(218, 218, 218, 0.5);
    padding: 3px 8px;
    border-radius: 4px;
    cursor: pointer;
}

.paybox .rightCon p.inBrdr span {
    width: 8px;
    height: 8px;
    display: inline-block;
    background: #f3f3f3;
    border-radius: 50%;
    margin-right: 3px;
}

.paybox .rightCon p.inBrdr.actives {
    background: #e6e6e6;
    border-color: #b0b0b0;
}

.paybox .rightCon p.inBrdr.actives span {
    background: #656565;
}

.paybox .choosers {
    display: none;
    line-height: 25px;
}

.paybox .choosers p {
    font-size: 12px;
    color: #767a8a;
}

.paybox .choosers p span {
    font-weight: 600;
    color: #000;
}

@media screen and (max-width: 767px) {
    .paybox .choosers .text-right {
        text-align: left !important;
    }
    .paybox .choosers .text-right a {
        padding-left: 15px !important;
        display: block;
    }
}

.paybox.dShow .choosers {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.newModal .modal-header {
    border-bottom: 0px;
    padding: 15px 25px;
}

.newModal .modal-header h4 {
    border-bottom: solid 1px #ededed;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    padding-bottom: 15px;
    color: #000000;
    font-weight: 600;
    font-size: 18px;
}

.newModal .modal-header h4 img {
    float: right;
    cursor: pointer;
}

.newModal .modal-body {
    padding: 15px 25px;
}

.newModal .modal-body p {
    font-size: 18px;
    color: black;
    text-align: center;
    line-height: 30px;
}

.newModal .modal-body .pobox {
    border: solid 2px rgba(218, 218, 218, 0.5);
    border-radius: 8px;
}

.newModal .modal-body .pobox .leftImg {
    width: 88px;
    height: 88px;
    background: #f3f3f3;
    border-radius: 4px;
}

.newModal .modal-body .pobox .rightCon p {
    display: inline-block;
    font-size: 12px;
    color: #292f49;
    margin-bottom: 6px;
    line-height: 22px;
}

.newModal .modal-body .pobox .rightCon p.inBrdr {
    border: solid 1px rgba(218, 218, 218, 0.5);
    padding: 3px 8px;
    border-radius: 4px;
    cursor: pointer;
}

.newModal .modal-body .pobox .rightCon p.inBrdr span {
    width: 8px;
    height: 8px;
    display: inline-block;
    background: #f3f3f3;
    border-radius: 50%;
    margin-right: 3px;
}

.newModal .modal-body .pobox .rightCon p.inBrdr.actives {
    background: #e6e6e6;
    border-color: #b0b0b0;
}

.newModal .modal-body .pobox .rightCon p.inBrdr.actives span {
    background: #656565;
}

.newModal .modal-footer {
    border-top: 0px;
    padding: 15px 25px;
}

.newModal .modal-footer .btn {
    background: #1a1a1a;
    color: white;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    font-size: 14px;
    font-weight: 600;
    border: solid 1px #1a1a1a;
    padding: 15px 0;
}

.newModal .modal-footer .btn.btn-outline {
    background: white;
    color: #1a1a1a;
}

.newModal .modal-footer.jcc {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.pr_lists .products {
    padding: 15px;
    border: solid 1px #e1e3ec;
    border-left: none;
    border-top: 0;
}

.pr_lists .products:hover {
    -webkit-box-shadow: 0 8px 15px -3px rgba(0, 0, 0, 0.16);
    box-shadow: 0 8px 15px -3px rgba(0, 0, 0, 0.16);
}

.pr_lists .products:hover .remove {
    display: block;
}

.pr_lists .products.active {
    background: #f5f5f5;
    border-radius: 5px;
}

.pr_lists .products .remove {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 99;
    opacity: 0.5;
    display: none;
    width: 23px;
}

.pr_lists .products .imgss {
    position: relative;
}

.pr_lists .products .imgss:hover .remove {
    display: block;
}

.pr_lists .products .imgss:hover::before {
    display: block;
    cursor: pointer;
}

.pr_lists img {
    width: 100%;
    margin-bottom: 13px;
}

.pr_lists h4 {
    color: #292f49;
    font-weight: 600;
    font-size: 15px;
}

@media screen and (max-width: 992px) {
    .pr_lists h4 {
        font-size: 12px;
    }
}

.pr_lists p {
    font-size: 14px;
    color: #7f8292;
    font-weight: 600;
    margin: 0;
}

@media screen and (max-width: 992px) {
    .pr_lists p {
        font-size: 12px;
    }
}

.CardBlock {
    border: solid 1px #ececec;
    background: white;
    padding: 25px;
    -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
}

.CardBlock h4 {
    font-size: 18px;
    font-weight: 600;
    color: #1a1a1a;
}

.CardBlock p {
    color: #757575;
    margin-bottom: 0;
}

.CardBlock span {
    display: inline-block;
    padding: 3px 15px;
    color: white;
    border-radius: 3px;
    font-size: 12px;
    display: none;
    margin-top: 13px;
}

.CardBlock span img {
    margin-right: 10px;
}

.CardBlock .col-md-6 {
    color: #292f49;
}

.CardBlock.Adds {
    font-size: 50px;
    width: 70px;
    text-align: center;
    line-height: 0px;
    height: 70px;
    /* display: flex; */
    padding: 20px 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: transparent;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .CardBlock.Adds {
        display: block;
        margin-bottom: 15px;
        padding: 14px;
        width: 50px;
        height: 50px;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .CardBlock.Adds img {
        width: 22px;
    }
}

@media screen and (max-width: 767px) {
    .CardBlock.Adds {
        margin-bottom: 15px;
    }
}

.CardBlock .c_de h4 {
    font-size: 16px;
    color: #767a8a;
}

.CardBlock.active {
    border-color: #164ea2;
    position: relative;
    background: #164ea2;
    cursor: pointer;
    color: white;
    -webkit-box-shadow: 0 28px 32px -17px rgba(0, 0, 0, 0.16);
    box-shadow: 0 28px 32px -17px rgba(0, 0, 0, 0.16);
}

.CardBlock.active .col-md-6 {
    color: white;
}

.CardBlock.active h4 {
    color: white;
}

.CardBlock.active p {
    color: white;
}

.CardBlock.active span {
    display: inline-block;
}

@media screen and (max-width: 767px) {
    .CardBlock {
        display: block;
        margin-bottom: 15px;
    }
    .CardBlock.mr-3 {
        margin-right: 0px !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .CardBlock {
        padding: 15px;
        margin-top: 15px;
    }
}

.fa {
    font-family: "FontAwesome";
}

.popups {
    position: absolute;
    top: 130px;
    left: 50%;
    padding: 20px;
    background: white;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    color: #236653;
    font-weight: 600;
}

@media screen and (max-width: 767px) {
    .popups {
        width: 45%;
        top: 10%;
        left: 50%;
        text-align: center;
    }
}

@media screen and (max-width: 480px) {
    .popups {
        width: 80%;
        top: 230px;
        left: 50%;
        text-align: center;
    }
}

.popups img {
    margin-right: 10px;
}

.nameW {
    display: block;
    width: 130px;
    height: 130px;
    max-height: 172px;
    background: #f4ebe1;
    border-radius: 50%;
    /* line-height: 188px; */
    text-align: center;
    font-size: 50px;
    color: #a1a1a1;
    font-weight: bold;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media screen and (max-width: 767px) {
    .nameW {
        margin-bottom: 20px;
    }
}

.ratings {
    background: #fff;
    border: solid 2px #ced1de;
    border-radius: 5px;
}

.ratings [class*=col-] {
    border-right: solid 1px #ededed;
    padding-top: 13px;
    padding-bottom: 13px;
    font-size: 14px;
    color: #757575;
}

@media screen and (max-width: 767px) {
    .ratings [class*=col-] {
        border: none;
    }
}

.ratings [class*=col-] span {
    float: right;
    color: #1a1a1a;
    font-weight: 600;
}

.ratings [class*=col-] span.r_s {
    float: none;
    font-size: 16px;
    margin-right: 5px;
}

.ratings [class*=col-] span.Positive {
    color: #236653;
}

.ratings [class*=col-] span.Neutral {
    color: #cd8416;
}

.ratings [class*=col-] span.Negative {
    color: #b43434;
}

.ratings [class*=col-]:last-child {
    border-right: 0;
}

.blcks {
    border: solid 1px rgba(218, 218, 218, 0.5);
    border-radius: 5px;
    margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
    .blcks {
        margin-bottom: 20px;
    }
}

.blcks span {
    color: #757575;
}

.blcks span.r_s {
    font-size: 20px;
}

.blcks span.Positive {
    color: #236653;
}

.blcks span.Neutral {
    color: #cd8416;
}

.blcks span.Negative {
    color: #b43434;
}

.blcks p {
    color: #292f49;
    margin: 15px 0 0;
}

.msgdetail {
    border: solid 1px #e1e3ec;
    border-radius: 4px;
    padding: 20px;
    background: #f9f9fb;
}

.msgdetail h3 {
    color: #1a1a1a;
    font-size: 18px;
    font-weight: 600;
    float: left;
    width: 100%;
    line-height: 46px;
    padding-bottom: 15px;
    border-bottom: solid 1px rgba(218, 218, 218, 0.5);
}

.msgdetail h3 img {
    width: 46px;
    height: 46px;
    float: left;
    margin-right: 15px;
    border-radius: 50%;
}

.msgdetail .mesgs .imgs {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 19px;
}

.msgdetail .mesgs .imgs img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 5px;
}

.msgdetail .mesgs .txts {
    background: #f7f7f7;
    border: solid 1px #ebebeb;
    padding: 10px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 250px;
    flex: 0 0 250px;
    border-radius: 4px;
    max-width: 250px;
    margin-right: 5px;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    border-radius: 0 10px 10px 10px;
}
.msgdetail .u-messgae.mesgs .txts {
    background: #194ea2;
    border: solid 1px #ebebeb;
    border-radius: 10px 0 10px 10px;
    
}

.msgdetail .mesgs .txts p {
    font-size: 13px;
    color: #ffffff;
    margin: 0;
}
.msgdetail .u-messgae.mesgs .txts p {
    font-size: 13px;
    color: #ffffff;
    margin: 0;
}

.msgdetail .mesgs .dats {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.msgdetail .mesgs .dats p {
    font-size: 13px;
    color: #a9a9a9;
    margin: 6px 0 0;
}

.msgdetail .mesgs.u-messgae {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.msgdetail .mesgs.u-messgae .dats {
    margin-right: 10px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

@media screen and (max-width: 767px) {
    .msgdetail .mesgs.u-messgae {
        display: block !important;
    }
    .msgdetail .mesgs.u-messgae .imgs {
        float: right;
    }
    .msgdetail .mesgs.u-messgae .txts {
        float: left;
    }
}

@media screen and (max-width: 767px) {
    .msgdetail .mesgs {
        display: block !important;
    }
    .msgdetail .mesgs .d-flex {
        display: block !important;
    }
    .msgdetail .mesgs .imgs {
        float: left;
    }
    .msgdetail .mesgs .txts {
        width: calc(100% - 60px);
        float: right;
    }
    .msgdetail .mesgs .dats {
        clear: both;
        padding-top: 10px;
        text-align: center;
    }
}

.msgdetail .msgFooter {
    margin: 0 0px;
}

.msgdetail .msgFooter button {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
}

@media screen and (max-width: 767px) {
    .msgdetail .pl-0 {
        padding-left: 15px !important;
    }
    .msgdetail button {
        margin-top: 15px;
    }
}

.conthanks {
    display: none;
}

.conthanks img {
    margin-right: 15px;
}

.conthanks p {
    font-size: 14px;
    font-weight: 500;
    color: #236653;
    padding-top: 14px;
}

.switch-wrap {
    cursor: pointer;
    background: #1a1a1a;
    padding: 2px;
    width: 36px;
    height: 20px;
    position: relative;
    border-radius: 25px;
    z-index: 5;
    float: right;
    margin-right: 100px;
}

.switch-wrap input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.switch-wrap .switch {
    height: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 0fr 1fr 1fr;
    grid-template-columns: 0fr 1fr 1fr;
    -webkit-transition: 0.2s;
    transition: 0.2s;
}

.switch-wrap .switch::after {
    content: "";
    border-radius: 50%;
    background: white;
    -ms-grid-column: 2;
    grid-column: 2;
    -webkit-transition: background 0.2s;
    transition: background 0.2s;
}

.switch-wrap .lbles {
    position: relative;
    font-size: 12px;
    font-weight: 400;
}

.switch-wrap .lbles::before {
    position: absolute;
    left: 0;
    top: -16px;
    content: "All time";
    color: #292f49;
    -webkit-transform: translateX(calc(-100% - 10px));
    transform: translateX(calc(-100% - 10px));
    white-space: nowrap;
}

.switch-wrap .lbles::after {
    position: absolute;
    right: 0;
    top: -16px;
    content: "Last 12 months";
    color: #757575;
    -webkit-transform: translateX(calc(100% + 5px));
    transform: translateX(calc(100% + 5px));
    white-space: nowrap;
}

.switch-wrap input:checked+.switch {
    -ms-grid-columns: 1fr 1fr 0fr;
    grid-template-columns: 1fr 1fr 0fr;
}

.switch-wrap input:checked+.switch::after {
    background-color: white;
}

.switch-wrap input:checked+.switch+.lbles::before {
    color: #757575;
}

.switch-wrap input:checked+.switch+.lbles::after {
    color: #292f49;
}

.bg_img {
    background: url("../images/bg.png");
}

.bg_img .homes {
    background: transparent;
}

.bg_img .homes .container-fluid {
    padding: 0 5%;
}

@media screen and (max-width: 992px) {
    .bg_img .homes .container-fluid {
        padding: 0 0px;
    }
}

@media screen and (max-width: 767px) {
    .bg_img .homes .container-fluid {
        padding: 0 4% 0 10px;
    }
}

@media screen and (max-width: 992px) {
    .bg_img .homes .container-fluid .nPd {
        -ms-flex-wrap: inherit;
        flex-wrap: inherit;
    }
}

@media screen and (max-width: 992px) {
    .bg_img .homes .container-fluid .nPd .logo img {
        width: 100px;
    }
}

@media screen and (max-width: 767px) {
    .bg_img .homes nav.navbar {
        padding: 0 0 0 0;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
}

.bg_img .homes .navbar-nav li {
    padding: 0 15px;
}

.bg_img .homes .navbar-nav li a {
    display: block;
    color: #92b0cf;
    font-weight: 400;
    font-size: 13px;
}


.bg_img .homes .collapse.show .navbar-nav li a {
    color: #082a50;
}
.mb-4.average-rating.d-flex.align-items-center{
    text-transform: capitalize;
}
@media screen and (min-width: 993px) and (max-width: 1220px) {
    .bg_img .homes .navbar-nav li a {
        font-size: 12px;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .bg_img .homes .navbar-nav li:first-child {
        padding-left: 0px;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .bg_img .homes .navbar-nav .pl-0 {
        padding-left: 0px;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .bg_img .homes .navbar-nav {
        padding-left: 0 !important;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.bg_img .homes .btn-groups {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media screen and (max-width: 992px) {
    .bg_img .homes .btn-groups {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media screen and (max-width: 767px) {
    .bg_img .homes .btn-groups {
        margin-left: 25px;
    }
}

@media screen and (max-width: 330px) {
    .bg_img .homes .btn-groups {
        margin-left: 10px;
    }
}

.bg_img .homes .btn-groups a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 12px 15px;
    color: #9ebbd8;
    font-weight: 400;
    font-size: 13px;
}

@media screen and (max-width: 767px) {
    .bg_img .homes .btn-groups a {
        padding: 4px 15px 0 0;
    }
}

@media screen and (max-width: 330px) {
    .bg_img .homes .btn-groups a {
        padding: 4px 5px 0 0;
    }
}

.bg_img .homes .btn-groups a.signups {
    background: #164ea2;
    border-radius: 5px;
    padding: 13px 20px;
    color: white;
    font-weight: 600;
}

@media screen and (max-width: 767px) {
    .bg_img .homes .btn-groups a.signups {
        padding: 5px 10px;
    }
}

@media screen and (min-width: 768px) {
    .bg_img .homes .navbar-toggler {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .bg_img .homes .navbar-toggler {
        position: absolute;
        right: 0px;
        top: 9px;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border: solid 1px #fff;
    }
    .bg_img .homes .navbar-toggler i {
        color: white;
        display: none;
    }
}

@media screen and (max-width: 330px) {
    .bg_img .homes .navbar-toggler {
        right: -7px;
    }
}

@media screen and (max-width: 767px) {
    .bg_img .homes .navbar-collapse {
        padding: 15px;
        background: white;
        border-radius: 10px;
        margin: 15px 0;
        position: absolute;
        z-index: 40;
        top: 40px;
        width: 100%;
        left: 0;
    }
    .bg_img .homes .navbar-collapse ul {
        margin: 0;
        padding-left: 0px !important;
    }
    .bg_img .homes .navbar-collapse li {
        text-align: left;
        display: block !important;
        width: 100%;
        padding: 10px 0;
        border-bottom: solid 1px #efefef;
    }
}

.bg_img .Hmesliders {
    background: transparent;
    padding-bottom: 110px !important;
}

.bg_img .Hmesliders .barndName h1 {
    font-size: 3rem;
    font-weight: 500;
    color: white;
}

.bg_img .Hmesliders .barndName p {
    font-size: 1.3rem;
    color: #9ebbd8;
    line-height: 2rem;
    margin: 35px 0;
}

@media screen and (max-width: 992px) {
    .bg_img .Hmesliders .barndName {
        max-width: 100%;
        margin-bottom: 20px;
        float: none;
        margin-left: 0;
        text-align: center;
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .bg_img .Hmesliders .btn-groups {
        margin-top: 5px;
    }
}

.bg_img .Hmesliders .btn-groups button {
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 12px 30px;
    min-width: 157px;
    margin-right: 10px;
    border-radius: 5px;
}

@media screen and (max-width: 767px) {
    .bg_img .Hmesliders .btn-groups button {
        margin-bottom: 15px;
    }
}

.bg_img .Hmesliders .btn-groups button.btn-outline {
    border: solid 1px #9ebbd8;
    color: #9ebbd8;
    background: transparent;
    font-weight: 600;
}

@media screen and (max-width: 1024px) {
    .bg_img .Hmesliders img {
        max-width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .whyus img {
        max-width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .whyus img {
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 767px) {
    .whyus a {
        margin-bottom: 20px;
        display: block;
    }
}

@media screen and (max-width: 767px) {
    .whyus a img {
        margin-bottom: 0px;
    }
}

.pricings .nav {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 0px;
    margin: 0 15px 20px;
    background: rgba(25, 93, 196, 0.1);
    border-radius: 4px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 5px;
    border: none;
    margin-bottom: 20px;
}

.pricings .nav li {
    width: 50%;
}

.pricings .nav li a {
    border: none;
    text-transform: uppercase;
    border-width: 0 0 1px;
    color: #292f49;
    padding: 12px 5px;
    font-weight: 600;
}

.pricings .nav li a.active {
    border-color: #000000;
    color: #000;
}

.pricings .sub_plan_box {
    border-width: 2px;
    min-height: 360px;
    border-radius: 8px;
    border: none;
    -webkit-box-shadow: 0 0px 32px -19px rgba(0, 24, 64, 0.2);
    box-shadow: 0 0px 32px -19px rgba(0, 24, 64, 0.2);
}

.pricings .sub_plan_box.sub_active {
    background: white;
    border: solid 2px #164ea2;
}

.pricings .sub_plan_box.sub_active h3 {
    color: auto;
}

.pricings .sub_plan_box.sub_active .device_d span::before {
    color: #767a8a;
}

.pricings .sub_plan_box.sub_active h3 {
    color: #000 !important;
}

.pricings .sub_plan_box.sub_active h3.Trial {
    color: #767a8a !important;
}

.pricings .sub_plan_box.sub_active h3.Light {
    color: #648eb9 !important;
}

.pricings .sub_plan_box.sub_active h3.Plus {
    color: #ad702c !important;
}

.pricings .sub_plan_box.sub_active h3.Pro {
    color: #164ea2 !important;
}

.pricings .sub_plan_box.sub_active p {
    color: #000;
}

.pricings .sub_plan_box.sub_active a {
    background: #164ea2;
    color: white;
    border-color: #164ea2;
}

.pricings .sub_plan_box:hover {
    background: white;
    border: solid 2px #164ea2;
}

.pricings .sub_plan_box:hover .device_d span::before {
    color: #767a8a;
}

.pricings .sub_plan_box:hover a {
    background: #164ea2;
    color: white;
    border-color: #164ea2;
}

.pricings .sub_plan_box:hover h3 {
    color: #000 !important;
}

.pricings .sub_plan_box:hover h3.Trial {
    color: #767a8a !important;
}

.pricings .sub_plan_box:hover h3.Light {
    color: #648eb9 !important;
}

.pricings .sub_plan_box:hover h3.Plus {
    color: #ad702c !important;
}

.pricings .sub_plan_box:hover h3.Pro {
    color: #164ea2 !important;
}

.pricings .sub_plan_box:hover p {
    color: #000;
}

.pricings .sub_plan_box .plan_price {
    padding-top: 40px;
    padding-bottom: 20px;
}

.pricings .sub_plan_box .plan_price .year_date {
    color: #767a8a;
}

.pricings .sub_plan_box .plan_price h3 {
    color: #000;
    font-size: 32px;
    margin-bottom: 5px;
}

@media screen and (max-width: 992px) {
    .pricings .sub_plan_box .plan_price h3 {
        font-size: 24px;
    }
}

.pricings .sub_plan_box .plan_price .offer_price {
    color: #236653;
    font-size: 14px;
    font-weight: 500;
}

.pricings .sub_plan_box .btn {
    width: 80%;
    /* margin-top: 30px; */
    padding: 8px 0;
    font-weight: 500;
    color: #164ea2;
}

@media screen and (max-width: 480px) {
    .pricings .sub_plan_box .btn {
        width: 70%;
    }
}

.pricings .switches {
    position: relative;
    display: inline-block;
}

.pricings .switches .switch-wrap {
    cursor: pointer;
    background: #1a1a1a;
    padding: 2px;
    width: 36px;
    height: 20px;
    position: relative;
    border-radius: 25px;
    z-index: 5;
    float: none;
    margin-right: 15px;
    margin-left: 15px;
}

.pricings .switches .switch-wrap .switch {
    height: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 0fr 1fr 1fr;
    grid-template-columns: 0fr 1fr 1fr;
    -webkit-transition: 0.2s;
    transition: 0.2s;
}

.pricings .switches .switch-wrap .switch::after {
    content: "";
    border-radius: 50%;
    background: white;
    -ms-grid-column: 2;
    grid-column: 2;
    -webkit-transition: background 0.2s;
    transition: background 0.2s;
}

.pricings .switches .switch-wrap::before,
.pricings .switches .switch-wrap::after {
    display: none;
}

.pricings .switches span {
    display: inline-block;
    vertical-align: top;
    color: #000000;
    font-weight: 600;
    font-size: 14px;
}

.pricings .switches span.on {
    opacity: 0.5;
}

.pricings .switches input:checked+.off+.switch-wrap>.switch {
    -ms-grid-columns: 1fr 1fr 0fr;
    grid-template-columns: 1fr 1fr 0fr;
}

.pricings .switches input:checked+.off+.switch-wrap>.switch::after {
    background-color: white;
}

.pricings .switches input:checked+.off+.switch-wrap+.on {
    opacity: 1;
}

.pricings .switches input:checked+.off {
    opacity: 0.5;
}

.pricings .switches input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9999;
    cursor: pointer;
}

.pricings .savings {
    color: black;
    font-size: 14px;
    font-weight: 600;
    vertical-align: top;
    margin-left: 15px;
}

.pricings .p-blocks {
    border: 1px solid #e6e6e6;
    padding: 15px;
    border-radius: 8px;
    position: relative;
    height: 100%;
    padding-bottom: 120px;
    -webkit-box-shadow: 0px 4px 8px -4px rgba(26, 26, 26, 0.2);
    box-shadow: 0px 4px 8px -4px rgba(26, 26, 26, 0.2);
}

.pricings .p-blocks p {
    font-size: 14px;
    color: black;
}

.pricings .p-blocks h4 {
    font-size: 32px;
    font-weight: bold;
    color: black;
    margin: 40px 0 15px;
}

.pricings .p-blocks h4 span {
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    vertical-align: middle;
}

.pricings .p-blocks h5 {
    font-size: 16px;
    margin: 20px 0 15px;
    color: #292f49;
}

.pricings .p-blocks ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.pricings .p-blocks ul li {
    max-width: calc(50% + 45px);
    padding-left: 25px;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #292f49;
    background: url(../images/svg/ic20-success.svg) no-repeat left top;
}

.pricings .p-blocks .actionBar {
    position: absolute;
    bottom: 0;
    width: calc(100% - 30px);
    padding-bottom: 15px;
}

.pricings .p-blocks button {
    background: #1a1a1a;
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding: 20px 10px;
    width: 100%;
    text-align: center;
    border: 0;
    border-radius: 4px;
    margin-bottom: 15px;
}

.pricings .p-blocks .iconss {
    width: 60px;
    position: absolute;
    right: 15px;
    top: 15px;
}

.pricings .p-blocks.active {
    -webkit-box-shadow: 0px 4px 8px -4px rgba(26, 26, 26, 0.2);
    box-shadow: 0px 4px 8px -4px rgba(26, 26, 26, 0.2);
    border: 2px solid #adadad;
}

@media screen and (max-width: 767px) {
    .pricings .pr-0 {
        padding-right: 15px !important;
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 767px) {
    .pricings .pl-0 {
        padding-left: 15px !important;
        margin-top: 15px;
    }
}

.testimonal .carousel ol.carousel-indicators {
    bottom: -30px;
}

.testimonal .carousel ol.carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgba(118, 122, 138, 0.3);
    opacity: 1;
    border: 0;
}

.testimonal .carousel ol.carousel-indicators li.active {
    background: #090f1d;
}

.testimonal .carousel .carousel-control-prev {
    position: absolute;
    left: 30px;
    color: #292f49;
    width: 40px;
    font-size: 30px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 1;
    background: white;
    height: 40px;
    border: solid 1px #ced2de;
    border-radius: 50%;
}

@media screen and (max-width: 992px) {
    .testimonal .carousel .carousel-control-prev {
        top: 35%;
        left: 0;
    }
}

@media screen and (max-width: 767px) {
    .testimonal .carousel .carousel-control-prev {
        display: none;
    }
}

.testimonal .carousel .carousel-control-next {
    position: absolute;
    right: 30px;
    color: #292f49;
    width: 40px;
    font-size: 30px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 1;
    background: white;
    height: 40px;
    border: solid 1px #ced2de;
    border-radius: 50%;
}

@media screen and (max-width: 992px) {
    .testimonal .carousel .carousel-control-next {
        top: 35%;
        right: 0;
    }
}

@media screen and (max-width: 767px) {
    .testimonal .carousel .carousel-control-next {
        display: none;
    }
}

.testimonal .carousel .tblocks {
    border: none;
    -webkit-box-shadow: 0 0px 32px -19px rgba(0, 24, 64, 0.2);
    box-shadow: 0 0px 32px -19px rgba(0, 24, 64, 0.2);
    background: white;
    border-radius: 4px;
    padding: 15px 15px 60px;
    margin-bottom: 15px;
    position: relative;
    min-height: 300px;
    padding-top: 25px;
}

@media screen and (max-width: 992px) {
    .testimonal .carousel .tblocks {
        padding: 10px;
    }
}

.testimonal .carousel .tblocks .test_client_pro {
    position: absolute;
    bottom: 20px;
    width: 100%;
    padding: 0 5px;
}

@media screen and (max-width: 992px) {
    .testimonal .carousel .tblocks .test_client_pro {
        position: inherit;
    }
}

.testimonal .carousel .tblocks p {
    font-size: 14px;
    line-height: 24px;
    color: #292f49;
    margin-bottom: 40px;
}

@media screen and (max-width: 992px) {
    .testimonal .carousel .tblocks p {
        font-size: 12px;
        line-height: 22px;
    }
}

.testimonal .carousel .tblocks img {
    margin-right: 15px;
    width: 50px;
    height: 50px;
    float: left;
    margin-bottom: 10px;
    border-radius: 50%;
}

.testimonal .carousel .tblocks h5 {
    font-size: 15px;
    color: #292f49;
    margin-bottom: 3px;
    padding-top: 5px;
}

@media screen and (max-width: 992px) {
    .testimonal .carousel .tblocks h5 {
        font-size: 13px;
    }
}

.testimonal .carousel .tblocks span {
    font-size: 12px;
    color: #656565;
}

.nUsers .mem_box:before {
    position: absolute;
    content: "";
    background: url(../images/m-bg.png);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    width: 80%;
    height: 80%;
    -webkit-filter: brightness(0.6);
    filter: brightness(0.6);
    z-index: -2;
    left: 0;
    -webkit-animation: zoom-in-zoom-out 5s ease infinite;
    animation: zoom-in-zoom-out 5s ease infinite;
    top: -14px;
    right: 0;
    margin: 0 auto;
}

@media screen and (max-width: 992px) {
    .nUsers .mem_box:before {
        top: -34px;
    }
}

@media screen and (max-width: 767px) {
    .nUsers .mem_box:before {
        width: 75%;
        height: 84%;
        top: -40px;
    }
}

@media screen and (max-width: 480px) {
    .nUsers .mem_box:before {
        top: -18px;
    }
}

@-webkit-keyframes zoom-in-zoom-out {
    0% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    50% {
        -webkit-transform: scale(1.2, 1.2);
        transform: scale(1.2, 1.2);
    }
    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@keyframes zoom-in-zoom-out {
    0% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    50% {
        -webkit-transform: scale(1.2, 1.2);
        transform: scale(1.2, 1.2);
    }
    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

.nUsers span {
    width: 180px;
    height: 180px;
    background: white;
    display: inline-block;
    border-radius: 50%;
    -webkit-box-shadow: 0 10px 14px -10px rgba(0, 0, 0, 0.16);
    box-shadow: 0 10px 14px -10px rgba(0, 0, 0, 0.16);
    font-weight: 400;
    font-size: 34px;
    color: #003b75;
    line-height: 200px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;
}

@media screen and (max-width: 992px) {
    .nUsers span {
        width: 120px;
        font-size: 20px;
        height: 120px;
    }
}

.nUsers p {
    margin-top: 40px;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    color: #003b75;
}

@media screen and (max-width: 767px) {
    .nUsers p {
        margin-bottom: 30px;
        margin-top: 15px;
    }
}

footer {
    background: #012f5b;
}

footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

footer ul li {
    display: inline-block;
    padding: 15px 10px;
}

footer ul li a {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #9ebbd8;
}

@media screen and (max-width: 767px) {
    footer [class*=col-] {
        text-align: center !important;
    }
}

footer .btmRow ul li {
    color: #9ebbd8;
    font-weight: 400;
    font-size: 12px;
    opacity: 0.5;
}

footer .btmRow ul li a {
    color: #9ebbd8;
    font-weight: 400;
    font-size: 12px;
}

@media screen and (max-width: 767px) {
    .crntp {
        margin-top: 20px;
    }
}

@media screen and (max-width: 767px) {
    .crntp .col-md-5 {
        padding: 0px !important;
        margin-bottom: 15px;
    }
}

.crntp .cu_head h2 {
    font-weight: 500;
}

.crntp .cu_head .cancle_plan {
    color: #b43434;
    font-size: 16px;
}

@media screen and (max-width: 767px) {
    .crntp .cu_head .cancle_plan {
        font-size: 12px;
    }
}

@media screen and (max-width: 767px) {
    .crntp .choosers .col-md-5 {
        padding: 0px 15px 0 !important;
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 767px) {
    .paymnt_edit .col-md-6 {
        padding: 0px 0px 0 0 !important;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 767px) {
    .upld {
        margin-top: 20px;
    }
    .upld button {
        width: 100%;
    }
}

.brdr-halfs {
    position: relative;
}

.brdr-halfs::after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 66.66666%;
    height: 1px;
    background: rgba(218, 218, 218, 0.5);
    content: "";
}

.brdr-top-half {
    position: relative;
}

.brdr-top-half::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 66.66666%;
    height: 1px;
    background: rgba(218, 218, 218, 0.5);
    content: "";
}

.dntAccnt a {
    font-weight: 600;
    text-decoration: underline;
    color: #292f49;
}

.confrim_box span.tickss {
    width: 200px;
    height: 200px;
    background: white;
}

@media screen and (max-width: 767px) {
    .cOrder .ones {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
}

@media screen and (min-width: 767px) {
    .navbar-header {
        display: none;
    }
    .navbar-collapse.collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0;
        overflow: visible !important;
    }
    .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
}

@media screen and (max-width: 992px) {
    .navbar-header {
        width: 100%;
        text-align: right;
    }
    .navbar-header button {
        background: transparent;
        outline: none;
        border: none;
    }
}

.product_delete_pop .modal-header {
    border: none;
}

.product_delete_pop .modal-header .close {
    border: solid 3px #e1e3ec;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 0;
    margin-right: 0px;
}

.product_delete_pop .modal-header .close span {
    font-size: 12px;
}

.product_delete_pop .delete_pop {
    text-align: center;
    padding: 0px 30px 0px;
}

.product_delete_pop .delete_pop h2 {
    font-size: 24px;
    color: #090f1d;
    margin: 20px 0 0;
    line-height: 34px;
}

.product_delete_pop .modal-footer .btn {
    border-radius: 5px;
}

.product_delete_pop .modal-footer .d_d {
    background: #b43434;
    color: white;
}

.message_list {
    max-height: 370px;
    overflow: auto;
    height: 370px;
}

.update_plan .close {
    border: solid 3px #e1e3ec;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 0;
    margin-right: 0px;
}

.update_plan .close span {
    font-size: 14px;
}

.update_plan .modal-footer button {
    border-radius: 4px;
}

.heading_count span {
    color: #767a8a;
    font-size: 14px;
    display: inline-block;
    width: 25px;
    height: 25px;
    /* border: solid 2px; */
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 10px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.items_list .item_list1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    position: relative;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: solid 1px #e1e3ec;
    padding: 0 10px 15px 0;
    margin-bottom: 20px;
}

.items_list .item_list1 h4 {
    font-size: 14px;
    color: #090f1d;
}

.items_list .item_list1 a {
    color: #090f1d;
    font-weight: 500;
    margin-right: 20px;
    font-size: 14px;
}

.items_list .item_list1 .attention {
    display: block;
    color: #b87614;
    font-size: 14px;
    margin-top: 10px;
}

.items_list .item_list1 .icon-close_1 {
    position: absolute;
    right: 0;
    color: #b43434;
}

.editproduct_form .modal-dialog {
    max-width: 100%;
}

.editproduct_form .modal-dialog .close {
    color: #767a8a;
    font-size: 14px;
    display: inline-block;
    width: 25px;
    height: 25px;
    border: solid 2px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 10px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.editproduct_form .modal-dialog .modal-title {
    color: #090f1d;
    font-size: 24px;
}

.commnet_tag {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.commnet_tag p {
    background: #f4ebe1;
    margin: 0 8px 0px 0;
    padding: 5px 15px;
    border-radius: 30px;
    font-size: 14px;
    min-width: 100px;
    text-align: center;
    display: inline-block;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.commnet_tag p span {
    font-weight: 500;
    color: #4f4335;
}

.commnet_tag p span b {
    margin-right: 5px;
    font-weight: 500;
    color: #4f4335;
}

.commnet_tag p i {
    font-size: 10px;
    margin-left: 10px;
}

.tag_note {
    font-size: 13px;
    color: #767a8a;
}

.pro_nub {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 30px;
    margin: 0 7px;
    height: 25px;
    font-size: 12px;
    border: solid 1px #ccc;
    border-radius: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.hrd {
    text-decoration: underline;
}

.select2-container.select2-container--open {
    width: auto !important;
    display: block;
}

.overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.49);
    width: 100%;
    top: 0px;
    height: 100%;
    left: 0px;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    z-index: 999;
}

.overlay.fade {
    opacity: 1;
    visibility: visible;
}

#loadMore {
    color: #090f1d !important;
    cursor: pointer;
    font-weight: 600;
}

.menu-btn {
    display: none;
}

@media screen and (max-width: 991px) {
    .menu-btn {
        display: block;
        text-align: right;
        cursor: pointer;
        margin-bottom: 0px;
        margin-top: 20px;
        margin-right: 4px;
    }
}

.menu-btn i {
    color: #000;
    font-size: 1.25rem;
}

header {
    background: #f3f3f3;
    padding: 10px 0 0;
}

header .header_top {
    border-bottom: solid 1px #e7e7e7;
    padding: 0 0 15px !important;
}

@media screen and (max-width: 767px) {
    header .logo img {
        width: 130px;
    }
}

@media screen and (max-width: 992px) {
    header .mob_search_icon {
        display: none;
    }
}

@media screen and (max-width: 992px) {
    header .mob_search_icon {
        display: block;
    }
}

@media screen and (max-width: 992px) {
    header .nav_ser {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

@media screen and (max-width: 767px) {
    header .nav_ser {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        display: none;
    }
}

@media screen and (max-width: 992px) {
    header .nav_ser.show {
        display: block;
    }
}

header .nav_ser .search_bar {
    width: 75%;
}

@media screen and (max-width: 992px) {
    header .nav_ser .search_bar {
        margin: 0 auto;
    }
}

@media screen and (max-width: 767px) {
    header .nav_ser .search_bar {
        margin: 20px auto 0;
        width: 100%;
    }
}

header .nav_ser .search_bar .form-group input {
    padding: 0 40px 0 10px;
}

header .nav_ser .search_bar .form-group .input_icon {
    top: 16px;
}

@media screen and (max-width: 767px) {
    header .header_right {
        width: 100%;
    }
}

header .header_right ul {
    padding: 0;
    margin: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
    header .header_right ul {
        width: 100%;
        margin-top: 15px;
    }
}

header .header_right ul li {
    list-style: none;
    position: relative;
    margin: 0 0px 0 20px;
}

@media screen and (max-width: 767px) {
    header .header_right ul li {
        min-width: auto;
        margin: 0 0px 0 7px;
    }
}

header .header_right ul li .btn-black {
    padding: 4px 10px;
    font-size: 14px;
}

@media screen and (max-width: 767px) {
    header .header_right ul li .btn-black {
        min-width: auto;
    }
}

header .header_right ul li .noti_nub {
    position: absolute;
    right: -8px;
    top: -8px;
    background: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: solid 1px #ccc;
    font-size: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

header .header_right ul li .n_icon {
    display: inline-block;
    width: 35px;
    height: 35px;
    background: white;
    border-radius: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

header .header_right ul li .user_img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
}

header .heade_menu {
    margin-top: 20px;
}

@media screen and (max-width: 992px) {
    header .heade_menu .navbar {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
}

header .heade_menu .navbar .menu_list .nav-item.active a {
    font-weight: 600;
}

header .heade_menu .navbar .menu_list .nav-item a {
    font-size: 14px;
    color: #1a1a1a;
}

.breadcumb_sec {
    border-bottom: solid 1px #e7e7e7;
}

.breadcumb_sec .breadcumb_list .breadcrumb {
    margin-bottom: 0;
    padding: 10px 0;
}

.breadcumb_sec .breadcumb_list .breadcrumb .breadcrumb-item {
    padding: 5px 0;
}

.breadcumb_sec .breadcumb_list .breadcrumb .breadcrumb-item a {
    font-size: 12px;
    color: #292f49;
}

.dashboard-content {
    padding: 20px 0;
}


/* .dashboard-sec {
  padding: 0 30px 0 0;
  Dashboard sidebar 
}*/

.dashboard-sec {
    padding: 0 0px 0 0;
    /* Dashboard sidebar */
}

.search_cat_top .row {
    margin: 0;
}

@media screen and (max-width: 991px) {
    .dashboard-sec {
        padding: 0;
    }
}

.dashboard-sec .col-lg-3 {
    background-color: #f9f9fb;
    padding: 20px 15px 0 35px;
    -webkit-box-shadow: inset -11px 2px 8px -15px rgba(0, 0, 0, 0.16);
    box-shadow: inset -11px 2px 8px -15px rgba(0, 0, 0, 0.16);
}

@media screen and (max-width: 1370px) {
    .dashboard-sec .col-lg-3 {
        padding: 20px 15px 0 24px;
    }
}

@media screen and (max-width: 991px) {
    .dashboard-sec .col-lg-3 {
        padding-top: 0;
    }
}

.dashboard-sec .dashboard-sidebar {
    width: 100%;
    background-color: #f9f9fb;
    border-radius: 10px;
}

@media screen and (max-width: 1365px) {
    .dashboard-sec .dashboard-sidebar {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .dashboard-sec .dashboard-sidebar {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        -webkit-transition: 0.4s;
        transition: 0.4s;
        position: fixed;
        top: 0px;
        left: 0px;
        border-radius: 0;
        height: 100%;
        z-index: 999999;
        width: 18.125rem;
        overflow-y: auto;
    }
}

@media screen and (max-width: 991px) {
    .dashboard-sec .dashboard-sidebar {
        width: 26.125rem;
        padding: 10px 15px;
    }
}

.dashboard-sec .dashboard-sidebar.show {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.dashboard-sec .dashboard-sidebar .dashboard-sidebar-logo {
    max-width: 13.5rem;
    display: block;
    margin: 0 auto 2.6875rem;
}

.dashboard-sec .dashboard-sidebar .dashboard-menu-list li.active {
    background: #01c8e7;
}

.dashboard-sec .dashboard-sidebar .dashboard-menu-list li.active a {
    color: white !important;
}

.dashboard-sec .dashboard-sidebar .dashboard-menu-list li.active:nth-child(1) {
    border-radius: 10px 10px 0 0;
}

@media screen and (max-width: 991px) {
    .dashboard-sec .dashboard-sidebar .dashboard-menu-list li.active:nth-child(1) {
        border-radius: 0;
    }
}

.dashboard-sec .dashboard-sidebar .dashboard-menu-list li.active:nth-last-child(1) {
    border-radius: 0px 0px 10px 10px;
}

@media screen and (max-width: 991px) {
    .dashboard-sec .dashboard-sidebar .dashboard-menu-list li.active:nth-last-child(1) {
        border-radius: 0;
    }
}

.dashboard-sec .dashboard-sidebar .dashboard-menu-list li a {
    font-size: 1.25rem;
    padding: 1.25rem 2.5rem;
    border-bottom: solid 1px #7070702b;
    color: #313131;
    font-weight: 600;
}

.dashboard-sec .dashboard-sidebar .dashboard-menu-list li a:hover {
    text-decoration: none;
}

.dashboard-sec .dashboard-sidebar .dashboard-menu-list li:hover {
    text-decoration: none;
    color: #01c8e7;
    background-color: #01c8e7;
}

.dashboard-sec .dashboard-sidebar .dashboard-menu-list li:hover a {
    color: #fff;
}

.dashboard-sec .dashboard-sidebar .dashboard-menu-list li:last-child a {
    border-bottom: 0;
}

.filter_list .filter_side {
    max-height: 785px;
    padding: 0 10px 0 0;
    overflow: auto;
}

@media (max-width: 991px) {
    .filter_list .filter_side {
        max-height: none;
    }
}

.filter_list .filter_top .filter_head h4 {
    font-size: 16px;
    font-weight: 500 !important;
    margin-bottom: 14px;
}

.filter_list .filter_top .filter_head h4.page_head {
    font-size: 20px;
    margin-bottom: 30px;
}

.filter_list .filter_top ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
}

.filter_list .filter_top ul li {
    width: 19%;
    display: none;
    text-align: center;
    list-style: none;
    background: #fff;
    margin: 0 14px 15px 0;
    padding: 15px 0;
    border-radius: 5px;
}

@media screen and (max-width: 1200px) {
    .filter_list .filter_top ul li {
        width: 40%;
    }
}

@media screen and (max-width: 767px) {
    .filter_list .filter_top ul li {
        width: 44%;
    }
}

.filter_list .filter_top ul li.active {
    background: #292f49;
}

.filter_list .filter_top ul li.active span {
    color: #fff;
}

.filter_list .filter_top ul li span {
    display: block;
    font-size: 12px;
    color: #000;
    margin-top: 10px;
}

.filter_list .price_box {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 60%;
}

.filter_list .price_box .icon-minus {
    text-align: center;
    margin: 0 10px;
}

.filter_list .price_box .icon-minus::before {
    color: black;
}

.filter_list .price_box input {
    text-align: center;
    height: 40px;
}

.filter_list .filter_btn {
    background: transparent;
    padding: 10px 0;
}

.filter_list .filter_btn .btn-black {
    min-width: 96%;
    border-radius: 4px;
}

.filter_list .filter_btn p span {
    font-size: 9px;
    padding-left: 3px;
}

.filter_search_right .re_text h4 {
    font-size: 22px;
    font-weight: 500 !important;
    margin: 0;
}

@media screen and (max-width: 767px) {
    .filter_search_right .re_text {
        margin-bottom: 15px;
    }
}

.filter_search_right .search_cat_top {
    margin-bottom: 20px;
    border-bottom: solid 1px #e1e3ec;
    padding: 0 0 10px;
}

.filter_search_right .search_cat_top .row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.filter_search_right .search_cat_top .col-md-8 {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
}

@media screen and (max-width: 767px) {
    .filter_search_right .search_cat_top .col-md-8 {
        margin: 15px 0 0px;
    }
}

.filter_search_right .search_cat_top .form-group {
    margin: 0 -35px 0 5px;
}

.filter_search_right .search_cat_top .s_c {
    display: -webkit-box;
    display: -ms-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
    .filter_search_right .search_cat_top .s_c {
        margin: 0 0 15px;
    }
}

.filter_search_right .search_cat_top .s_c p {
    background: #f4ebe1;
    margin: 0 8px 10px 0;
    padding: 5px 15px;
    border-radius: 30px;
    font-size: 14px;
    min-width: 100px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media screen and (max-width: 767px) {
    .filter_search_right .search_cat_top .s_c p {
        font-size: 12px;
        padding: 5px 10px;
    }
}

.filter_search_right .search_cat_top .s_c p span {
    font-weight: 500;
    color: #4f4335;
}

.filter_search_right .search_cat_top .s_c p span b {
    margin-right: 5px;
    font-weight: 500;
    color: #4f4335;
}

.filter_search_right .search_cat_top .s_c p i {
    font-size: 10px;
    margin-left: 10px;
}

@media screen and (max-width: 992px) {
    .filter_search_right .search_cat_top .sort_by_box {
        margin: 0 0 0 5px;
    }
}

.filter_search_right .search_cat_top .sort_by_box p {
    padding: 0 50px 8px;
    font-size: 12px;
    border-radius: 4px;
    margin: 8px;
    color: #090f1d;
    font-weight: 600;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}

@media screen and (max-width: 1025px) {
    .filter_search_right .search_cat_top .sort_by_box p {
        padding: 0;
        margin: 0;
        display: block;
    }
}

@media screen and (max-width: 767px) {
    .filter_search_right .search_cat_top .sort_by_box p {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        text-align: right;
        white-space: nowrap;
        margin: 0 0 15px;
    }
}

.filter_search_right .search_cat_top .sort_by_box p span {
    font-size: 12px;
    margin-left: 7px;
    display: inline-block;
    margin-top: 2px;
}

.filter_search_right .search_cat_top .sort_by_box p b {
    color: #767a8a;
    padding-right: 4px;
    font-weight: 600;
}

@media screen and (max-width: 992px) {
    .filter_search_right .search_cat_top .sort_by_box p b {
        padding: 0;
        padding-right: 7px;
    }
}

.filter_search_right .search_cat_top .sort_by_box .sort_by_list {
    position: absolute;
    background: white;
    width: 100%;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    top: 45px;
    display: none;
    z-index: 9;
}

.filter_search_right .search_cat_top .sort_by_box .sort_by_list ul {
    padding: 15px 0;
    margin: 0;
}

.filter_search_right .search_cat_top .sort_by_box .sort_by_list ul li {
    list-style: none;
    padding: 10px 15px;
    font-size: 12px;
    color: #292f49;
    cursor: pointer;
}

.filter_search_right .search_cat_top .sort_by_box .sort_by_list ul li:hover {
    background: #164ea2;
    color: white;
}

.filter_search_right .search_cat_top .sort_by_box .sort_by_list ul li.activess {
    font-weight: 600;
}

/* aks lang list */


.filter_search_right .search_cat_top .sort_by_box .lang_list {
    position: absolute;
    background: white;
    width: 100%;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    top: 45px;
    display: none;
    z-index: 9;
}

.filter_search_right .search_cat_top .sort_by_box .lang_list ul {
    padding: 15px 0;
    margin: 0;
}

.filter_search_right .search_cat_top .sort_by_box .lang_list ul li {
    list-style: none;
    padding: 10px 15px;
    font-size: 12px;
    color: #292f49;
    cursor: pointer;
}

.filter_search_right .search_cat_top .sort_by_box .lang_list ul li:hover {
    background: #164ea2;
    color: white;
}

.filter_search_right .search_cat_top .sort_by_box .lang_list ul li.activess {
    font-weight: 600;
}


.filter_search_right .seach_filter input {
    padding: 9px 15px 9px 40px;
    border: none;
    font-size: 13px;
    height: auto;
    font-weight: 500;
}

@media screen and (max-width: 1025px) {
    .filter_search_right .seach_filter input {
        padding: 5px 7px 5px 35px;
        font-size: 11px;
    }
}

@media screen and (max-width: 767px) {
    .filter_search_right .seach_filter input {
        padding: 5px 7px 5px 20px;
        font-size: 10px;
    }
}

.filter_search_right .seach_filter .input_icon {
    top: 14px;
    right: auto;
    left: 30px;
}

@media screen and (max-width: 1025px) {
    .filter_search_right .seach_filter .input_icon {
        top: 6px;
    }
}

@media screen and (max-width: 992px) {
    .filter_search_right .seach_filter .input_icon {
        top: 6px;
    }
}

@media screen and (max-width: 767px) {
    .filter_search_right .seach_filter .input_icon {
        left: 20px;
        top: 8px;
    }
}

.tbles {
    border: solid 1px #e1e3ec;
    border-radius: 10px;
    display: block;
    width: 100%;
    overflow: auto;
}

.tbles h4 {
    padding-left: 10px;
    padding-top: 10px;
}

@media screen and (max-width: 1410px) {
    .tbles {
        display: block;
        width: 100%;
        overflow: auto;
    }
}

.tbles th {
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: #767a8a;
    padding: 10px 5px;
    border-bottom: solid 1px #e1e3ec;
}

.tbles th:nth-last-child(1) {
    font-weight: 600;
}

.tbles td {
    font-weight: 400;
    font-size: 12px;
    min-width: 50px;
    word-break: inherit;
    color: #292f49;
    text-align: center;
    padding: 10px 2px;
    border-bottom: solid 1px #e1e3ec;
}

@media screen and (max-width: 1370px) {
    .tbles td.text-right {
        padding: 10px 4px 10px;
    }
}

.tbles td:nth-last-child(1) {
    font-weight: 600;
}

.tbles td:nth-child(2) {
    /* font-weight: 600; */
}

.tbles tr:first-child td {
    font-weight: 600;
}

.tbles tr:last-child td {
    border-bottom: 0px;
}

.tbles .text-right {
    position: relative;
}

.tbles .more {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.9);
    top: 0px;
    bottom: 0px;
    display: none;
    text-align: right;
}

.tbles .more .more_box {
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    border: solid 1px rgba(41, 47, 73, 0.4);
    margin-top: 8px;
    background: white;
    line-height: 26px;
    border-radius: 3px;
}

.tbles .more span img {
    width: 3px;
}

.tbles .more ul {
    margin: 0;
    padding: 10px;
    border: solid 1px rgba(41, 47, 73, 0.4);
    border-radius: 8px;
    width: 200px;
    list-style: none;
    background: white;
    position: absolute;
    right: 0;
    display: none;
    top: 40px;
    z-index: 3;
}

.tbles .more ul li {
    display: block;
    text-align: left;
    border-bottom: solid 1px #dadada;
}

.tbles .more ul li a {
    display: block;
    padding: 15px 0;
    color: #292f49;
}

.tbles .more .edit img {
    width: 10px;
}

.tbles .more li span {
    padding-right: 10px;
}

.tbles .more li.delete a {
    color: #b43434;
}

.tbles tr:hover {
    cursor: pointer;
}

.tbles tr:hover td .more {
    display: block;
    cursor: pointer;
}

.tbles tr:hover td .more:hover ul {
    display: block;
}

.table_pagination {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.table_pagination a {
    background: white;
    /* box-shadow: 0 0 0; */
    display: block;
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 10px 0 0;
    border-radius: 50%;
    color: #767a8a;
    font-size: 12px;
    font-weight: 600;
}

.table_pagination a:nth-child(1) {
    border: solid 2px #e5e6ee;
}

.table_pagination a:nth-child(1):hover {
    background: white;
}

.table_pagination a:nth-last-child(1) {
    border: solid 2px #e5e6ee;
}

.table_pagination a:nth-last-child(1):hover {
    background: white;
}

.table_pagination a.active {
    background-color: #1a1a1a;
    color: #fff;
}

.table_pagination a:hover {
    background-color: #1a1a1a;
    color: #fff;
}

.table_pagination a .icon::before {
    color: #1a1a1a;
}

.compare_list {
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    border-radius: 8px;
    padding: 10px 10px 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.compare_list .c_box1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
}

.compare_list .c_box1:before {
    position: absolute;
    content: "";
    background: #ccc;
    width: 1px;
    height: 45%;
    right: -28px;
}

.compare_list .c_box1.c_btn button {
    min-width: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 5px 15px;
}

@media screen and (max-width: 1024px) {
    .compare_list .c_box1.c_btn button {
        font-size: 10px;
    }
}

.compare_list .c_box1.c_btn::before {
    display: none;
}

.compare_list .c_box1 i.icon_black:before {
    color: black;
    margin: 0 20px 0 0;
}

.compare_list .c_box1 i.icon_b {
    background: #1a1a1a;
    width: 20px;
    height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 10px 0 0;
    cursor: pointer;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
}

.compare_list .c_box1 p {
    margin: 0;
}

.compare_list .c_box1 p b {
    margin-right: 10px;
}

.details_page {
    padding-bottom: 30px;
}

.details_page .product_name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 30px;
}

.details_page .product_name .icon {
    border: solid 2px #cdcdcd;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.details_page .product_name h4 {
    margin: 0 20px;
}

.details_page .product_name p {
    color: #767a8a;
}

.details_page .pro_images .simpleLens-gallery-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media screen and (max-width: 992px) {
    .details_page .pro_images .simpleLens-gallery-container {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 992px) {
    .details_page .pro_images .simpleLens-gallery-container .simpleLens-thumbnails-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-top: 15px;
    }
}

.details_page .pro_images .simpleLens-gallery-container .simpleLens-thumbnails-container a {
    border: solid 2px #ededed;
    width: 80px;
    height: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 8px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 0 15px;
    border-radius: 7px;
}

.details_page .pro_images .simpleLens-gallery-container .simpleLens-thumbnails-container a img {
    width: 100%;
}

@media screen and (max-width: 992px) {
    .details_page .pro_images .simpleLens-gallery-container .simpleLens-thumbnails-container a {
        margin-right: 15px;
        width: 58px;
        height: 58px;
        padding: 9px;
    }
}

@media screen and (max-width: 767px) {
    .details_page .pro_images .simpleLens-gallery-container .simpleLens-thumbnails-container a {
        margin-right: 5px;
        width: 50px;
        height: 50px;
    }
}

.details_page .pro_images .simpleLens-gallery-container .simpleLens-container {
    background: white;
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
    margin-left: 22px;
    width: 100%;
    border-radius: 10px;
}

@media screen and (min-width: 995px) and (max-width: 1200px) {
    .details_page .pro_images .simpleLens-gallery-container .simpleLens-container {
        width: 80%;
    }
}

@media screen and (max-width: 992px) {
    .details_page .pro_images .simpleLens-gallery-container .simpleLens-container {
        margin-left: 0;
    }
}

@media screen and (max-width: 992px) {
    .details_page .pro_images .simpleLens-gallery-container .simpleLens-container .simpleLens-mouse-cursor {
        display: none;
    }
}

@media screen and (max-width: 992px) {
    .details_page .pro_images .simpleLens-gallery-container .simpleLens-container .simpleLens-lens-element {
        display: none;
    }
}

.details_page .cmnt_box {
    border: solid 2px #e5e5e5;
    padding: 12px;
    border-radius: 8px;
}

.details_page .c_price {
    -webkit-box-shadow: 0 7px 12px -2px rgba(0, 0, 0, 0.16);
    box-shadow: 0 7px 12px -2px rgba(0, 0, 0, 0.16);
    padding: 15px;
    border-radius: 10px;
}

.details_page .c_price .c_pri_d {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.details_page .c_price .add_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.details_page .c_price .add_btn a.btn {
    min-width: 48%;
    border-radius: 4px;
    border: solid 1px #ced2de;
    font-weight: 600;
    font-size: 1.25rem;
}

.details_page .c_price .add_btn a.btn.btn-black {
    border: none;
}

@media screen and (max-width: 480px) {
    .rating .pl-1 {
        padding: 0 !important;
    }
}

@media screen and (max-width: 767px) {
    .rating .col-lg-3.col-md-4.pl-0 {
        padding-right: 0;
    }
}

.rating .rating_list {
    margin-bottom: 30px;
}

.rating .rating_list ul {
    padding: 0;
    margin: 0;
    background: #f3f3f3;
    border-radius: 8px;
}

.rating .rating_list ul li {
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 30%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-right: solid 3px #ededed;
    padding: 15px 12px 15px 12px;
}

.rating .rating_list ul li:nth-last-child(1) {
    border: none;
}

.rating .rating_list ul li span {
    display: block;
}

.rating .rating_comment {
    border: solid 2px #ededed;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 4px;
}

.filter_list.Jewelery_list .j_top li {
    width: 42%;
    padding: 10px 0;
}

.filter_list.Jewelery_list .j_top li span {
    margin-top: 0;
}

.product_card .col-md-3 {
    border-right: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
    padding-top: 15px;
}

.product_card .item {
    border-right: solid 1px #ccc;
}

@media screen and (max-width: 767px) {
    .product_card .item {
        border: none;
    }
}

.product_card .card_1 {
    margin-bottom: 20px;
}

.product_card .card_1 .card_img {
    border-radius: 8px;
    text-align: center;
    max-height: 160px;
    min-height: 160px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 10px;
}

.product_card .card_1 .card_img img {
    width: 80%;
}

.product_card .card_1 .card_details h4 {
    color: #7f8292 !important;
    font-weight: 500 !important;
}

.product_card .card_1 .card_details p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    font-size: 15px;
    font-weight: 600;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    word-break: break-word;
}

@media screen and (max-width: 1630px) {
    .product_card .card_1 .card_details p {
        font-size: 14px;
    }
}

.product_card .card_1 .card_details p a {
    color: #292f49;
}

.tbles.j_table {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.tbles.j_table tr td:nth-child(1) {
    font-weight: 600;
}

.tbles.j_table tr td:nth-child(2) {
    font-weight: 400;
}

.img360 {
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.addR {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 25px;
}

.addR img {
    border: solid 1px #ccc;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    padding: 5px;
    margin-bottom: 8px;
}

.pro_images {
    height: 100%;
}

.pro_images .simpleLens-gallery-container,
.pro_images .simpleLens-container {
    height: 100%;
}

@media screen and (max-width: 992px) {
    .pro_images .simpleLens-gallery-container,
    .pro_images .simpleLens-container {
        height: auto;
        width: 100%;
    }
}

.pro_images .simpleLens-gallery-container .simpleLens-big-image-container,
.pro_images .simpleLens-container .simpleLens-big-image-container {
    margin: 0 auto;
    width: 100%;
}

.c_pri_d .pro_d_text {
    width: 35%;
    position: relative;
}

@media screen and (max-width: 991px) {
    .c_pri_d .pro_d_text h4 {
        font-size: 12px;
    }
}

.c_pri_d .pro_d_text a {
    color: #090f1d;
}

.c_pri_d .pro_d_text::after {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0px;
    width: 1px;
    background: #ededed;
    content: "";
}

.c_pri_d .pro_d_text:nth-last-child(1):after {
    display: none;
}

.strike_red {
    color: red;
}

.pro_d_text a {
    color: #090f1d !important;
}

.not_a{
    color: #292f49;
}

.videoEdits .imgBlock .hover {
    right: 22px!important;
    bottom: 3px!important;
}

.navbar-toggler:focus, .navbar-toggler:hover {
    text-decoration: none;
    outline: none;
    box-shadow: none;
}
.ownCmnt.mesgs .txts p {
    color: #000000!important;
}

.dropdown-item:hover, .dropdown-item:focus {
    cursor: pointer;
}




























.filter_search_right .search_cat_top .sort_by_box .country_list {
    position: absolute;
    background: white;
    width: 100%;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    top: 45px;
    /* display: none; */
    display: block;
    z-index: 9;
}

.filter_search_right .search_cat_top .sort_by_box .country_list ul {
    padding: 15px 0;
    margin: 0;
}

.filter_search_right .search_cat_top .sort_by_box .country_list ul li {
    list-style: none;
    padding: 10px 15px;
    font-size: 12px;
    color: #292f49;
    cursor: pointer;
}

.filter_search_right .search_cat_top .sort_by_box .country_list ul li:hover {
    background: #164ea2;
    color: white;
}

.filter_search_right .search_cat_top .sort_by_box .country_list ul li.activess {
    font-weight: 600;
}

.phone_lists{
    position: absolute;
    background: white;
    min-width: 150px !important ;
    left: -40px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    top: 45px;
    display: none;
    z-index: 9;
    min-width: 100px;
    background-color: #194ea2 !important;
    overflow: hidden;
}
.phone_lists ul {
    padding-left: 0 !important;
    margin-bottom: 0 !important;
}
.phone_lists ul li{
list-style: none;
padding: 10px 15px;
font-size: 12px;
color: #fff;
cursor: pointer;
display: flex;
align-items: center;
}
.phone_lists ul li i{
    margin-right: 5px;
}
.filter_search_right .search_cat_top .form-group {
    margin: 0 0px 0 5px !important;
}